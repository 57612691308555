import React, { useEffect, useState } from "react";
import Loader from "../../components/Loader";
import { notifyError, notifySuccess } from "../../components/NotifyToast";
import QRCode from "react-qr-code";
import * as api from '../../service/api'
import dayjs from "dayjs";
import 'dayjs/locale/th';
import { Box, Typography, Button, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import backIcon from '../../assets/icons/backicon.svg'

export default function Consult() {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    const [currentTime, setCurrentTime] = useState(dayjs().format('HH:mm:ss'));
    const [name, setName] = useState(null)
    const [dataConferrence, setDataConferrence] = useState({})
    const [check, setCheck] = useState('')
    const [value, setValue] = useState('')

    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem('userData'))
        setName(userData['personal']['fname'] + ' ' + userData['personal']['lname'])
        const timer = setInterval(() => {
            setCurrentTime(dayjs().format('HH:mm:ss'));
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        createConferrenceRoom()
    }, [])

    const DateLongTH = () => {
        dayjs.locale("th");
        return dayjs().format("dddd D MMMM BBBB");
        // let buddhistEra = require('dayjs/plugin/buddhistEra')
        // dayjs.extend(buddhistEra)
        // return dayjs().format('dddd D MMMM BBBB')
    };

    const createConferrenceRoom = async () => {
        setCheck('checkData')
        // setLoading(true)
        // let res = await api.createConferrenceRoom()
        // setLoading(false)
        // if (res) {
        //     if (res['data']['code'] === 200) {
        //         setDataConferrence(res['data']['data'])
        //         setCheck('checkData')
        //         setValue(res['data']['data']['url'])
        //     } else {
        //         notifyError(res['data']['message'])
        //     }
        // } else {
        //     notifyError('ไม่ตอบสนอง')
        // }
    }

    const clickCreateConferrence = (url) => {
        window.open(url, "_system")
    }

    return (
        <>
            <Loader loading={loading} />
            <Box sx={{
                color: '#263238',
                display: 'grid',
                gridGap: 20,
                fontSize: 18,
                margin: { xl: '2rem', lg: '2rem', md: '2rem', sm: '2rem', xs: '2rem 1rem' },
                '@media (max-width: 1023px)': {
                    gridGap: '3%',
                    fontSize: 16,
                },
                '@media (max-width: 800px)': {
                    gridGap: '1%',
                    fontSize: 14,
                }
            }}>
                <Box sx={{ display: 'flex', gridGap: 10, cursor: 'pointer' }} onClick={() => { navigate('/mainMenu') }}>
                    <img src={backIcon} />
                    <div>ย้อนกลับ</div>
                </Box>
                <Box sx={{
                    textAlign: 'center',
                    color: '#2CC2C0',
                    fontSize: 24,
                    '@media (max-width: 800px)': {
                        fontSize: 18,
                    }
                }}
                >ปรึกษา</Box>
                <Box sx={{
                    width: 500,
                    margin: 'auto',
                    border: '1px solid #E1E3E9',
                    borderRadius: '15px',
                    background: '#FFFFFF',
                    padding: 6,
                    '@media (max-width: 1100px)': {
                        width: 400,
                        padding: 4
                    },
                    '@media (max-width: 500px)': {
                        width: '100%',
                        padding: 2
                    },
                }}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: 'auto',
                    }}>
                        <Box sx={{
                            borderRadius: '15px',
                            display: "flex",
                            flexDirection: "column",
                            width: '100%',
                        }}>
                            <div style={{
                                border: '1px solid #E1E3E9',
                                borderRadius: '15px',
                            }}>
                                <Box sx={{
                                    borderRadius: '15px 15px 0px 0px',
                                    backgroundColor: '#E1F4EB',
                                    padding: '1rem',
                                    // width: '100%'
                                }} >
                                    <div style={{
                                        padding: '15px'
                                    }}>
                                        <Typography variant="h3" sx={{
                                            '@media (max-width: 950px)': {
                                                fontSize: '40px',
                                                fontWeight: 'bold'
                                            },
                                        }} align='center'>
                                            {currentTime}
                                        </Typography>
                                        <Typography variant="body2" align='center'>
                                            วัน {DateLongTH()}
                                        </Typography>
                                    </div>
                                </Box>

                                <div style={{ padding: '1rem' }}>
                                    <div style={{ margin: '10px 0px 20px 0px' }}>
                                        <Typography sx={{ fontSize: '18px', my: '0.5rem', fontWeight: 'bold' }}>
                                            ชื่อผู้ใช้งาน
                                        </Typography>
                                        <TextField
                                            inputProps={
                                                { readOnly: true }
                                            }
                                            sx={{ width: '100%' }}
                                            value={name}
                                        />
                                    </div>
                                    <div>
                                        <Button
                                            sx={{ width: '100%' }}
                                            variant="contained"
                                            disabled={check === '' ? true : null}
                                            // onClick={() => { clickCreateConferrence(dataConferrence['url']) }}
                                            onClick={() => { clickCreateConferrence('https://vcr.pistreamplus.com/rooms/cbv-04a-7p2-lb0/join') }}
                                        >เข้าร่วม</Button>
                                    </div>

                                </div>
                            </div>

                            <Box sx={{
                                display: 'grid',
                                justifyItems: 'center',
                                gridGap: 10,
                                margin: '30px 0'
                            }} >
                                <Box>
                                    <QRCode
                                        // value={value}
                                        value={"https://vcr.pistreamplus.com/rooms/cbv-04a-7p2-lb0/join"}
                                        size={150}
                                    />
                                </Box>
                                <div>สแกน Qr Code เพื่อเข้าร่วม</div>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}