import React, { useEffect, useState } from "react";
import * as api from '../../service/api'
import { Box, FormControl, MenuItem, Pagination, Select } from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';
import Loader from "../../components/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { notifyError } from "../../components/NotifyToast";
import backIcon from '../../assets/icons/backicon.svg'

export default function MedicalInformation() {

    const location = useLocation()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    const [hospital, setHospital] = useState('')
    const [optionHospital, setOptionHospital] = useState([])
    const [date, setDate] = useState('')
    const [optionDate, setOptionDate] = useState([])

    const [dataInformation, setDataInformation] = useState([])
    const [dataPersonal, setDataPersonal] = useState([])
    const [dataAll, setDataAll] = useState([])

    const [notData, setNotData] = useState('')

    const [page, setPage] = useState(1)
    const [maxPage, setMaxPage] = useState(1)
    const [showList, setShowList] = useState([])

    const [pid,setPid]= useState(new URLSearchParams(location.search).get('PID'))

    useEffect(() => {
        findHospital()
    }, [])

    useEffect(() => {
        setDataPersonal([])
        setDataInformation([])
        setNotData('')
        setDate('')
        setOptionDate([])

        findInformationPersonal()
        findInformation()
    }, [hospital])

    useEffect(() => {
        if (date === '' || date === ' ') {
            findInformation()
        } else {
            filterDataInformation()
        }
    }, [date])

    const findInformation = async () => {
        setLoading(true)
        let res = await api.findInformation(pid, hospital === ' ' ? '' : hospital)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                let dataAll = res['data']['data']
                dataAll.sort(function (a, b) {
                    return new Date(b.medicalDate) - new Date(a.medicalDate)
                })
                setDataInformation(res['data']['data'])
                setDataAll(res['data']['data'])

                const List = []
                for (let i = 0; i < res['data']['data'].length; i++) {
                    if (res['data']['data'][i]) {
                        List.push(res['data']['data'][i]['medicalDate'])
                    }
                }
                setOptionDate(List)

            } else {
                setNotData(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const findInformationPersonal = async () => {
        setLoading(true)
        let res = await api.findInformationPersonal(pid, hospital === ' ' ? '' : hospital)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                setDataPersonal(res['data']['data'])
                setMaxPage((Math.ceil(res['data']['data'].length / 1)))
            } else {
                notifyError(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const findHospital = async () => {
        setLoading(true)
        let res = await api.findInformationPersonal(pid, hospital)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                let option = []
                for (let i = 0; i < res['data']['data'].length; i++) {
                    if (res['data']['data'][i]) {
                        option.push({
                            key: res['data']['data'][i]['hos_id'],
                            value: res['data']['data'][i]['hos_name']
                        })
                    }
                }
                const unique = [...new Map(option.map((m) => [m.key, m])).values()] //กรองชื่อซ้ำ
                setOptionHospital(unique)
                console.log(unique)
            } else {
                notifyError(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const convertDate = (dataDate) => {
        const date = new Date(dataDate)
        const optionDate = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timeZone: 'UTC',
        }

        const thaiLocale = 'th-TH';
        const formattedDate = date.toLocaleDateString(thaiLocale, optionDate)

        const newDate = `${formattedDate}`
        return newDate
    }

    const filterDataInformation = () => {
        const List = []
        for (let i = 0; i < dataAll.length; i++) {
            if (dataAll[i]['medicalDate'] === date) {
                List.push(dataAll[i])
            }
        }
        setDataInformation(List)
    }

    useEffect(() => {
        let start = page * 1 - 1
        setShowList(dataPersonal.slice(start, start + 1))
    }, [page, dataPersonal])

    const handlePageChange = (event, page) => {
        setPage(page)
    }

    return (
        <div>
            <Loader loading={loading} />
            <Box sx={{
                color: '#263238',
                display: 'grid',
                gridGap: 20,
                fontSize: 18,
                margin: { xl: '2rem', lg: '2rem', md: '2rem', sm: '2rem', xs: '2rem 1rem' },
                '@media (max-width: 1023px)': {
                    gridGap: '3%',
                    fontSize: 16,
                },
                '@media (max-width: 800px)': {
                    gridGap: '1%',
                    fontSize: 14,
                }
            }}>
                <Box sx={{display: 'flex', gridGap: 10}} onClick={()=> {navigate('/mainMenu')}}>
                    <img src={backIcon} />
                    <div>ย้อนกลับ</div>
                </Box>

                <Box sx={{
                    textAlign: 'center',
                    fontSize: 24,
                    '@media (max-width: 800px)': {
                        fontSize: 18,
                    }
                }}
                >ข้อมูลทางการแพทย์</Box>

                <Box sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    border: 1,
                    borderRadius: 2,
                    borderColor: '#E1E3E9',
                    padding: '20px',
                    '@media (max-width: 1023px)': {
                        gridTemplateColumns: '1fr',
                        padding: '15px'
                    },
                    '@media (max-width: 800px)': {
                        justifyItems: 'center',
                        padding: '10px'
                    },
                    bgcolor: '#FFF',
                }}>
                    <FormControl sx={{ width: '90%', color: '#2CC2C0' }} size="small">
                        <div>โรงพยาบาลที่เข้ารับบริการ</div>
                        <Select
                            value={hospital || ' '}
                            onChange={(event) => {
                                const {
                                    target: { value },
                                } = event;
                                setHospital(value)
                            }}
                        >
                            <MenuItem value=" ">ทั้งหมด</MenuItem>
                            {optionHospital.map((item, index) => (
                                <MenuItem
                                    key={index}
                                    value={item['key']}
                                >
                                    {item['value']}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl sx={{ width: '90%', color: '#2CC2C0' }} size="small">
                        <div>ค้นหาวันที่</div>
                        <Select
                            value={date || ' '}
                            onChange={(event) => {
                                const {
                                    target: { value },
                                } = event;
                                setDate(value)
                            }}
                        >
                            <MenuItem value=" ">วันที่ทั้งหมด</MenuItem>
                            {optionDate.map((item, index) => (
                                <MenuItem
                                    key={index}
                                    value={item}
                                >
                                    {convertDate(item)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>


                {showList.map((i, idx) => (
                    <Box key={idx} sx={{ display: 'grid', gridGap: '4%' }}>
                        <Box sx={{
                            border: 1,
                            borderRadius: 2,
                            borderColor: '#E1E3E9',
                            padding: '20px',
                            '@media (max-width: 1023px)': {
                                padding: '15px'
                            },
                            '@media (max-width: 800px)': {
                                padding: '10px'
                            },
                            bgcolor: '#FFF',
                        }}>
                            <Box sx={{ color: '#2CC2C0' }}>ข้อมูลส่วนตัว</Box>
                            <div></div>

                            <Box sx={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr 1fr 1fr',
                                gridGap: '20px',
                                '@media (max-width: 1023px)': {
                                    gridTemplateColumns: '1fr 1fr',
                                    gridGap: '15px'
                                },
                                '@media (max-width: 800px)': {
                                    gridTemplateColumns: '1fr',
                                    gridGap: '10px',
                                }
                            }} key={idx}>
                                <div>
                                    <div style={{ color: '#2CC2C0' }}>ชื่อ-นามสกุล</div>
                                    <div>{i['pname'] + i['fname'] + ' ' + i['lname']}</div>
                                </div>
                                <div>
                                    <div style={{ color: '#2CC2C0' }}>ที่อยู่</div>
                                    <div>{i['address']['house_no'] + ' ' + 'หมู่ ' + i['address']['village_no'] + ' ' + i['address']['full_address']}</div>
                                </div>
                                <div>
                                    <div style={{ color: '#2CC2C0' }}>เลขประจำตัวประชาชน</div>
                                    <div>{i['pid']}</div>
                                </div>
                                <div>
                                    <div style={{ color: '#2CC2C0' }}>โทรศัพท์</div>
                                    <div>{i['home_phone'] || '-'}</div>
                                </div>
                                <div>
                                    <div style={{ color: '#2CC2C0' }}>วันเกิด</div>
                                    <div>{convertDate(i['birthday'])}</div>
                                </div>
                                <div>
                                    <div style={{ color: '#2CC2C0' }}>โทรศัพท์มือถือ</div>
                                    <div>{i['phone_number'] || '-'}</div>
                                </div>
                                <div>
                                    <div style={{ color: '#2CC2C0' }}>อายุ</div>
                                    <div>{i['age'] + ' ปี'}</div>
                                </div>
                            </Box>

                        </Box>

                        <Box sx={{
                            border: 1,
                            borderRadius: 2,
                            borderColor: '#E1E3E9',
                            padding: '20px',
                            '@media (max-width: 1023px)': {
                                padding: '15px'
                            },
                            '@media (max-width: 800px)': {
                                padding: '10px'
                            },
                            bgcolor: '#FFF',
                        }}>
                            <box style={{ color: '#2CC2C0' }}>ข้อมูลทางการแพทย์</box>
                            <div></div>

                            <Box sx={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr 1fr 1fr',
                                gridGap: '20px',
                                '@media (max-width: 1023px)': {
                                    gridTemplateColumns: '1fr 1fr',
                                    gridGap: '15px'
                                },
                                '@media (max-width: 800px)': {
                                    gridTemplateColumns: '1fr',
                                    gridGap: '10px',
                                }
                            }}>
                                <div>
                                    <div style={{ color: '#2CC2C0' }}>หมายเลขผู้ป่วยนอก</div>
                                    <div>{i['hn']}</div>
                                </div>
                                <div>
                                    <div style={{ color: '#2CC2C0' }}>กรุ๊ปเลือด</div>
                                    <div>{i['blood_group'] || '-'}</div>
                                </div>
                                <div>
                                    <div style={{ color: '#2CC2C0' }}>แพ้ยา</div>
                                    <div>{i['drug_allergy'] || '-'}</div>
                                </div>
                            </Box>
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            margin: 'auto'
                        }}>
                            <Pagination
                                count={maxPage}
                                page={page}
                                onChange={handlePageChange}
                                shape="rounded"
                                color="primary"
                            />
                        </Box>
                    </Box>
                ))}

                {!notData ?
                    <>
                        <Box sx={{
                            display: 'flex',
                            gridGap: 15,
                            overflow: "hidden",
                            overflowX: "scroll",
                            marginBottom: '1rem',
                            paddingBottom: '50px',
                            marginTop: '30px',
                            '@media (max-width: 800px)': {
                                marginTop: '50px'
                            }
                        }}>
                            {dataInformation.map((i, idx) => (
                                <Box sx={{
                                    minWidth: '400px',
                                    backgroundColor: '#E1F4EB',
                                    borderRadius: 3,
                                    padding: 2,
                                    maxWidth: '300px',
                                    overflowY: 'auto',
                                    minHeight: '100%',
                                    maxHeight: '700px',
                                    marginBottom: '1rem'
                                }} key={idx}>
                                    <div style={{ fontWeight: '700', marginBottom: 5 }}>{convertDate(i['medicalDate'])}</div>
                                    <Box sx={{
                                        display: 'grid',
                                        gridGap: 15,
                                        '@media (max-width: 1023px)': {
                                            padding: 2,
                                            gridGap: 15,
                                        },
                                        '@media (max-width: 800px)': {
                                            padding: 1,
                                            gridGap: 8,
                                        }
                                    }}>
                                        <Box sx={{
                                            backgroundColor: '#FFFFFF',
                                            borderRadius: 2,
                                            padding: 2
                                        }}>
                                            <div>ประวัติการแพทย์</div>
                                            <div style={{ color: '#2CC2C0' }}>{i['medicalInformation']['hos_name']}</div>
                                            {i['medicalInformation']['visit'] ?
                                                null
                                                :
                                                <>
                                                    <div style={{ color: '#2CC2C0', margin: '0 5%' }}>CC</div>
                                                    <div style={{ margin: '0 10%' }}>{i['medicalInformation']['cc'] === '' ? '-' : i['medicalInformation']['cc']}</div>
                                                    <div style={{ color: '#2CC2C0', margin: '0 5%' }}>Ht</div>
                                                    <div style={{ margin: '0 10%' }}>{i['medicalInformation']['ht'] === '' ? '-' : i['medicalInformation']['ht']}</div>
                                                    <div style={{ color: '#2CC2C0', margin: '0 5%' }}>Pi</div>
                                                    <div style={{ margin: '0 10%' }}>{i['medicalInformation']['pi'] === '' ? '-' : i['medicalInformation']['pi']}</div>
                                                    <div style={{ color: '#2CC2C0', margin: '0 5%' }}>Bt</div>
                                                    <div style={{ margin: '0 10%' }}>{i['medicalInformation']['bt'] === '' ? '-' : i['medicalInformation']['bt']}</div>
                                                    <div style={{ color: '#2CC2C0', margin: '0 5%' }}>Pe</div>
                                                    <div style={{ margin: '0 10%' }}>{i['medicalInformation']['pe'] === '' ? '-' : i['medicalInformation']['pe']}</div>
                                                    <div style={{ color: '#2CC2C0', margin: '0 5%' }}>Rr</div>
                                                    <div style={{ margin: '0 10%' }}>{i['medicalInformation']['rr'] === '' ? '-' : i['medicalInformation']['rr']}</div>
                                                    <div style={{ color: '#2CC2C0', margin: '0 5%' }}>Bp</div>
                                                    <div style={{ margin: '0 10%' }}>{i['medicalInformation']['bp'] === '' ? '-' : i['medicalInformation']['bp']}</div>
                                                    <div style={{ color: '#2CC2C0', margin: '0 5%' }}>Bmi</div>
                                                    <div style={{ margin: '0 10%' }}>{i['medicalInformation']['bmi'] === '' ? '-' : i['medicalInformation']['bmi']}</div>
                                                    <div style={{ color: '#2CC2C0', margin: '0 5%' }}>Pr</div>
                                                    <div style={{ margin: '0 10%' }}>{i['medicalInformation']['pr'] === '' ? '-' : i['medicalInformation']['pr']}</div>
                                                    <div style={{ color: '#2CC2C0', margin: '0 5%' }}>sat O2</div>
                                                    <div style={{ margin: '0 10%' }}>{i['medicalInformation']['sat_o2'] === '' ? '-' : i['medicalInformation']['sat_o2']}</div>
                                                    <div style={{ color: '#2CC2C0', margin: '0 5%' }}>Bw</div>
                                                    <div style={{ margin: '0 10%' }}>{i['medicalInformation']['bw'] === '' ? '-' : i['medicalInformation']['bw']}</div>
                                                </>
                                            }

                                        </Box>

                                        {i['medicalInformation']['lab_list'] ?
                                            <Box sx={{
                                                backgroundColor: '#FFFFFF',
                                                borderRadius: 2,
                                                padding: 2
                                            }}>
                                                <div>ผลแลป</div>
                                                {i['medicalInformation']['lab_list'].map((item, index) => (
                                                    <div key={index} style={{ margin: '0 10%', display: 'flex', justifyContent: 'center', gridGap: 5 }}> <CircleIcon style={{ width: '5px' }} /> {item['detail']}</div>
                                                ))}
                                            </Box>
                                            : null
                                        }

                                        {i['medicalInformation']['diagnosis'] ?
                                            <Box sx={{ backgroundColor: '#FFFFFF', borderRadius: 2, padding: 2 }}>
                                                <div>การวินิจฉัย</div>
                                                {i['medicalInformation']['diagnosis'].map((item, index) => (
                                                    <div key={index} style={{ margin: '0 10%', display: 'flex', justifyContent: 'center', gridGap: 5 }}> <CircleIcon style={{ width: '5px' }} /> {item['diag']}</div>
                                                ))}
                                            </Box>
                                            : null
                                        }

                                        {i['medicalInformation']['order_list']?.['0'] ?
                                            <Box sx={{ backgroundColor: '#FFFFFF', borderRadius: 2, padding: 2 }}>
                                                <div>การจ่ายยา</div>
                                                {i['medicalInformation']['order_list'].map((item, index) => (
                                                    <div key={index} style={{ margin: '0 10%', display: 'flex', justifyContent: 'center', gridGap: 5 }}> <CircleIcon style={{ width: '5px' }} /> {item['med']}</div>
                                                ))}
                                            </Box>
                                            : null
                                        }
                                    </Box>

                                </Box>
                            ))}
                        </Box>
                    </>
                    : null
                }

                {notData ?
                    <div style={{ display: 'flex', justifyContent: 'center', margin: '10%', fontSize: 20 }}>ไม่พบข้อมูล</div>
                    : null
                }
            </Box>
        </div>
    )
}