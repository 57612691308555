import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createTheme } from '@mui/material';
import { Provider } from 'react-redux';
import store from './store/store';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2CC2C0',
      light: '#2CC2C0',
      dark: '#2CC2C0',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#FFFFFF',
      light: '#FFFFFF',
      dark: '#FFFFFF',
      contrastText: '#2CC2C0',
    },
    yellow: {
      main: '#FFF1A9',
      light: '#FFF1A9',
      dark: '#FFF1A9',
      contrastText: '#205567',
    },
  },
  typography: {
    allVariants: {
      color: '#205567'
    },
    fontFamily: [
      'IBM Plex Sans Thai Looped'
    ]
  },
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
