import React from 'react'
import styles from './ModalUpgrade.module.css'
import CloseIcon from '@mui/icons-material/Close';
import icon from '../../assets/images/upgradeVersion.svg'
import star from '../../assets/icons/star.svg'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function ModalUpgrade({ onClose }) {

    const navigate = useNavigate()

    return (
        <div className={styles.overlay}>
            <div className={styles.modalController}>
                <div className={styles.contentHeader}>
                    <CloseIcon color='primary' onClick={() => { onClose() }} />
                </div>
                <div className={styles.contentModal}>
                    <img src={icon} />
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography sx={{ fontSize: 20 }}>กรุณา Upgrade ระบบ</Typography>
                        <Typography sx={{ fontSize: 20 }}>เพื่อให้เข้าใช้งานได้ทุกฟังก์ชัน</Typography>
                    </Box>
                    <Button
                        color='yellow' variant="contained"
                        sx={{ display: 'flex', gridGap: '20px', alignContent: 'center' }}
                        onClick={() => {
                            navigate('/payServiceFee')
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography>ใช้งานระบบแบบ Upgrade</Typography>
                            <img src={star} />
                        </Box>
                        <KeyboardArrowRightIcon />
                    </Button>
                </div>
            </div>
        </div>
    )
}
