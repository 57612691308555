import './App.css';
import React, { useEffect, useState } from 'react';
import { HashRouter as Router, Route, Routes, Outlet } from 'react-router-dom';
import Login from './page/Login';
import GuideService from './page/GuideService';
import MainMenu from './page/MainMenu';
import MedicalInformation from './page/medicalInformation/MedicalInformation';
import CheckPermissions from './page/checkPermissions/CheckPermissions';
import AccessDenied from './page/AccessDenied';
import Consent from './page/ConSent';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HealthHistory from './page/healthWithIoT/HealthHistory';
import MedicalInformationNew from './page/medicalInformation/MedicalInformationNew';
import OffSiteLab from './page/offSiteLabResults/OffSiteLab';
import { AuthWrapper, PublicRoute } from './page/AuthWrapper';
import HealthResultsAnnual from './page/healthExamResultsAnnual/HealthResultsAnnual';
import CheckForwarding from './page/checkForwarding/CheckForwarding';
import AppointmentList from './page/appointmentList/AppointmentList';
import Consult from './page/consult/Consult';
import Refer from './page/referDoc/Refer';
import PayServiceFee from './page/PayServiceFee';

function App() {

  useEffect(() => {

  }, [])

  return (
    <div>
      <ToastContainer />
      <Router>
        <Routes>

          <Route element={<Outlet/>}>
            <Route path='/'
              element={
                <PublicRoute>
                  <Login />
                </PublicRoute>
              }
            />
            <Route path='/payServiceFee' element={<PayServiceFee />} /> {/* หน้าชำระค่าบริการ */}
          </Route>

          <Route
            element={
              <AuthWrapper>
                <Outlet />
              </AuthWrapper>}
          >
            <Route path='/guideService' element={<GuideService />} /> {/* หน้ากรุณาเสียบบัตร หรือ สแกนQR CODE */}
            <Route path='/consent' element={<Consent />} /> {/* consent page */}
            <Route path='/mainMenu' element={<MainMenu />} /> {/* menu page */}
            <Route path='/accessDenied' element={<AccessDenied />} /> {/* หน้าปฏิเสธการเข้าใช้งาน */}

            {/* ประวัติการรักษา ในโรงพยาบาลเครือข่าย */}
            < Route path='/medicalInformation' element={<MedicalInformation />} />
            < Route path='/medicalInformationNew' element={<MedicalInformationNew />} />

            {/* ตรวจสอบสิทธิ์ */}
            <Route path='/checkPermissions' element={<CheckPermissions />} />

            {/* คัดกรองสุขภาพ ผ่านอุปกรณ์ IoT */}
            <Route path='/healthHistory' element={<HealthHistory />} />

            {/* ผลตรวจแลปนอกสถานที่ */}
            <Route path='/offsiteLabResults' element={<OffSiteLab />} />

            {/* ผลตรวจสุขภาพประจำปี */}
            <Route path='/healthResultsAnnual' element={<HealthResultsAnnual />} />

            {/* ตรวจสอบ ข้อมูลการส่งต่อ */}
            <Route path='/checkForwarding' element={<CheckForwarding />} />

            {/*รายการนัดหมาย */}
            <Route path='/appointmentList' element={<AppointmentList />} />

            {/* ปรึกษา */}
            <Route path='/consult' element={<Consult />} />

            {/* เอกสารการส่งต่อ */}
            <Route path='/refer' element={<Refer />} />

          </Route>

        </Routes>
      </Router>
    </div>
  );
}

export default App;
