import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import moment from "moment";
import { logout } from '../store/authSlice'


export function AuthWrapper({ children }) {
    const authen = useSelector((state) => state.auth);
    const accessToken = localStorage.getItem('access_token')
    const location = useLocation()
    const dispatch = useDispatch()

    useEffect(() => {
        if (authen.isAuthenticated && accessToken) {
            // console.log(authen)
            let expire = authen.user.ExpireInTimestamp

            const current_time = Number(moment().add(8, 'hours').format("x"));
            const expire_time = expire * 1000;

            if (expire) {
                if (current_time > expire_time) {
                    console.log('token หมดอายุ')
                    localStorage.clear()
                    dispatch(logout())
                } else {

                }
            } else {
                localStorage.clear()
                dispatch(logout())
            }
        } else {
            console.log('ไม่มี auth')
        }

    }, [location])

    return (authen.isAuthenticated && accessToken ? children : <Navigate to='/' state={{ from: location }} />);
}

export function PublicRoute({ children }) {
    const authen = useSelector((state) => state.auth);
    let location = useLocation();
    const accessToken = localStorage.getItem('access_token')
    const dispatch = useDispatch()

    useEffect(() => {
        if (authen.isAuthenticated && accessToken) {
            // console.log(authen)
            let expire = authen.user.ExpireInTimestamp

            const current_time = Number(moment().add(8, 'hours').format("x"));
            const expire_time = expire * 1000;

            if (expire) {
                if (current_time > expire_time) {
                    console.log('token หมดอายุ')
                    localStorage.clear()
                    dispatch(logout())
                } else {

                }
            } else {
                localStorage.clear()
                dispatch(logout())
            }
        } else {
            console.log('ไม่มี auth')
        }

    }, [location])

    return (authen.isAuthenticated && accessToken ? <Navigate to='/guideService' state={{ from: location }} /> : children);
}