import React, { useEffect, useState } from "react";
import styles from './ModalAlert.module.css'
import exclamation from '../../assets/icons/exclamation.svg'

export default function ModalAlertAuto({ onClose, header, title }) {

    const [counter, setCounter] = useState(3)

    useEffect(() => {
        CountNumber()
    }, [counter]);

    const CountNumber = () => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000)
        if (counter === 0) {
            onClose()
        }
    }

    return (
        <div className={styles.overlay}>
            <div className={styles.modalController}>
                <div className={styles.contentModal}>
                    <img src={exclamation} />
                    <div className={styles.detail}>{header}</div>
                    <div className={styles.detail}>{title}</div>
                    <button className={styles.button} onClick={onClose}>ยืนยัน</button>
                </div>
            </div>
        </div>
    )

}