export const environment = {
    
    // PROD (production : true)
    // production: true,
    // endpoint_domain: '',
    // endpoint_socket: '',


    // UAT (production : false)
    // production: false,
    // endpoint_domain: 'https://api-healthatm-uat.numeddatacenter.com',
    // endpoint_socket: 'http://localhost:30051',
    // endpoint_api: 'http://localhost:30049',

    // local
    production: false,
    endpoint_domain: 'https://api-healthatm-uat.numeddatacenter.com',
    endpoint_socket: 'http://localhost:30050',
    endpoint_api: 'http://localhost:30049'
}