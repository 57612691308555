import React, { useState, useEffect } from "react";
import styles from './ModalUserManual.module.css'
import close from '../../assets/icons/close.svg'
import img1 from '../../assets/images/UserManual1.svg'
import img2 from '../../assets/images/UserManual2.svg'
import img3 from '../../assets/images/UserManual3.svg'
import back from '../../assets/icons/back.svg'
import next from '../../assets/icons/next.svg'
import backdis from '../../assets/icons/backdis.svg'
import nextdis from '../../assets/icons/nextdis.svg'
import { Box } from "@mui/material";

function Header(props) {
    let text = props.text || ''

    return (
        <div className={styles.headerContainer}>
            <div className={styles.text}>{text}</div>
        </div>
    )
}

export default function ModalUserManual(props) {
    const [pageIndex, setPageIndex] = useState(0)

    useEffect(() => {
        setPageIndex(0)
    }, [props.show])

    const backButton = () => {
        if (pageIndex > 0) {
            setPageIndex(pageIndex - 1)
        }
    }
    const nextButton = () => {
        if (pageIndex < 3) {
            setPageIndex(pageIndex + 1)
        }
    }

    const getTextHeader = (index) => {
        if (index === 0) {
            return 'ขั้นตอนที่ 1'
        } else if (index === 1) {
            return 'ขั้นตอนที่ 2'
        } else if (index === 2) {
            return 'ขั้นตอนที่ 3'
        }
    }

    return (
        <>
            <div className={styles.overlay}>
                <div className={styles.modalContainer}>
                    <div className={styles.closeButtonContainer}>
                        <img onClick={props.onClose} src={close}></img>
                    </div>
                    <div>
                        <Header text={getTextHeader(pageIndex)} number={pageIndex} />
                    </div>
                    <div className={styles.gap}>
                        <div className={styles.icon}>
                            {pageIndex !== 0 ? <img style={{ cursor: 'pointer' }} src={back} onClick={backButton} /> : <img src={backdis} />}
                        </div>
                        <div className={styles.imageContainer}>
                            <div className={styles.imageSlide}
                                style={{
                                    marginLeft: '-' + pageIndex * 100 + '%'
                                }}
                            >
                                <div className={styles.image}>
                                    <img src={img1} />
                                </div>
                                <div className={styles.image}>
                                    <img src={img2} />
                                </div>
                                <div className={styles.image}>
                                    <img src={img3} />
                                </div>
                            </div>
                        </div>
                        <div className={styles.icon2}>
                            {pageIndex !== 2 ? <img style={{ cursor: 'pointer' }} src={next} onClick={nextButton} /> : <img src={nextdis} />}
                        </div>
                    </div>
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        {pageIndex == 0 ?
                            <div style={{ display: 'flex', gridGap: '10px' }}>
                                <div className={styles.dotGreen}></div>
                                <div className={styles.dotGray}></div>
                                <div className={styles.dotGray}></div>
                            </div>
                            : pageIndex == 1 ?
                                <div style={{ display: 'flex', gridGap: '10px' }}>
                                    <div className={styles.dotGray}></div>
                                    <div className={styles.dotGreen}></div>
                                    <div className={styles.dotGray}></div>
                                </div>
                                :
                                <div style={{ display: 'flex', gridGap: '10px' }}>
                                    <div className={styles.dotGray}></div>
                                    <div className={styles.dotGray}></div>
                                    <div className={styles.dotGreen}></div>
                                </div>
                        }
                    </Box>
                </div>
            </div >
        </>
    )
}
