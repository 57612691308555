import axios from "axios";
import { environment } from '../config.env'

const ENDPOINT_DOMAIN = environment.endpoint_domain || ''
const domain = ENDPOINT_DOMAIN

const ENDPOINT_API = environment.endpoint_api || ''
const urlport = ENDPOINT_API

export function login(username, password) {
    return new Promise(resolve => {
        let url = `${domain}/portable-api/api/v1/authen/loginPlatform/HeathATM`
        let body = {
            "Username": username,
            "Password": password
        }
        let header = {
            'Content-Type': 'application/json'
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch((error) => {
            resolve(false)
            console.log(error)
        })
    })
}

export function refreshToken(RefreshToken) {
    return new Promise(resolve => {
        let url = `${domain}/portable-api/api/v1/authen/refreshToken/HeathATM`
        let body = {
            "RefreshToken": RefreshToken
        }
        let header = {
            'Content-Type': 'application/json'
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch((error) => {
            resolve(false)
            console.log(error)
        })
    })
}

export function profile() {
    return new Promise(resolve => {
        let url = `${domain}/portable-api/api/v1/authen/profile/HeathATM`
        let header = {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
        axios.get(url, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function findInformationNew(pid) {
    return new Promise(resolve => {
        let url = `${domain}/portable-api/api/v1/microservice/medical/findInformation/healthatm`
        let body = {
            "collection": "all",
            "pid": pid
        }
        let header = {
            'Platform': 'healthATM',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function findInformationIPD(pid, hos_id) {
    return new Promise(resolve => {
        let url = `${domain}/portable-api/api/v1/microservice/medical/findInformation/healthatm`
        let body = {
            "collection": [
                "visit_information_ipd",
                "labs_information_ipd",
                "order_information_ipd",
                "diagnosis_information_ipd",
                "proced_information_ipd"
            ],
            "pid": pid,
            "hos_id": hos_id
        }
        let header = {
            'Platform': 'healthATM',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function findInformation(pid, hos_id) {
    return new Promise(resolve => {
        let url = `${domain}/dashboard-api/api/v1/microservice/medical/findInformation/healthatm-dashboard?group=date`
        let body = {
            "collection": "all",
            "pid": pid,
            "hos_id": hos_id
        }
        let header = {
            'Platform': 'healthatm-dashboard',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function findInformationPersonal(pid, hos_id) {
    return new Promise(resolve => {
        let url = `${domain}/dashboard-api/api/v1/microservice/data/findInformation`
        let body = {
            "pid": pid,
            "hos_id": hos_id
        }
        let header = {
            'Platform': 'healthatm-dashboard',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

// export function checkConsent(pid) {
//     return new Promise(resolve => {
//         let url = `${domain}/dashboard-api/api/v1/microservice/concent/checkConcent?PID=${pid}`
//         let header = {
//             'Platform': 'other',
//             'Authorization': `Bearer ${localStorage.getItem('access_token')}`
//         }
//         axios.get(url, { headers: header }).then(res => {
//             resolve(res)
//         }).catch(() => {
//             resolve(false)
//         })
//     })
// }

export function confirmConsent(pid, name) {
    return new Promise(resolve => {
        let url = `${domain}/portable-api/api/v1/microservice/consent/card`
        let body = {
            "CID": pid,
            "Name": name,
            "Remark": "ข้าพเจ้ายินยอมให้บุคลากรทางการแพทย์เข้าถึงข้อมูลทางการแพทย์ได้ในภายใน 24 ชั่วโมงเท่านั้น",
            "Allow": [1]
        }
        let header = {
            'Platform': 'healthATM',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

// export function findMeasureResult(pid) {
//     return new Promise(resolve => {
//         let url = `${domain}/portable-api/api/v1/microservice/point-of-Care/findMeasureResult?order=CreatedDate&sort=desc&CID=${pid}`
//         let header = {
//             'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
//             'Platform': 'healthATM'
//         }
//         axios.get(url, { headers: header }).then(res => {
//             resolve(res)
//         }).catch(() => {
//             resolve(false)
//         })
//     })
// }
export function findMeasureResult(pid) {
    return new Promise(resolve => {
        let data = JSON.stringify({
            "token": "xdjz8M0/9uJYelhkMENId3NZYTFxdUUvWURZQnY0SHFxd1VZcUFycTJFdi9YRlVWVm1vPQ==",
            "NID": pid
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://api-med-devices.numeddatacenter.com/api/v1/tunnel/callback?endpoint=https%3A%2F%2Fmedcloud.radical-enlighten.com%2Fapi%2Frequest',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                resolve(false)
            });
    })
}

export function findBloodTestResult(pid) {
    return new Promise(resolve => {
        let url = `${domain}/dashboard-api/api/v1/microservice/point-of-Care/findBloodTestResult?PID=${pid}`
        let header = {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Platform': 'healthATM'
        }
        axios.get(url, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function getIndividualSummary(year) {
    return new Promise(resolve => {
        let url = `${urlport}/api/v1/public/individual/getIndividualSummary`
        let body = {
            "year": year
        }
        let header = {
            'Content-Type': 'application/json'
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function findAppointment(pid) {
    return new Promise(resolve => {
        let url = `${domain}/dashboard-api/api/v1/microservice/data/findAppointment?pid=${pid}`
        let header = {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Platform': 'healthATM',
            'Content-Type': 'application/json'
        }
        axios.get(url, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function createConferrenceRoom() {
    return new Promise(resolve => {
        let url = `https://microservice.numeddatacenter.com/api/microservice/v1/conferrence/createConferrenceRoom`
        let body = {}
        let header = {
            'Content-Type': 'application/json',
            'platform_type': 'R12Network-dashboard',
            'enviroment': environment.production ? 'PROD' : 'UAT',
            'Authorization': localStorage.getItem('access_token')
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}