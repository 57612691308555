import { Box, Button, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"
import { notifyError, notifyInfo } from '../components/NotifyToast'
import ModalAlertAuto from '../components/modal/ModalAlertAuto'
import * as api from '../service/api'

import logo from '../assets/images/logo.svg'
import iconLogo from '../assets/icons/logoIcon.svg'
import card from '../assets/images/guideIDcard.svg'
import thaiID from '../assets/icons/thaiID.svg'
import warning from '../assets/icons/warning.svg'

import { io } from 'socket.io-client';
import { environment } from "../config.env";

import { useDispatch, useSelector } from "react-redux";
import { login } from "../store/authSlice";
import ModalUserManual from "../components/modal/ModalUserManual";

const ENDPOINT_SOCKET = environment.endpoint_socket

export default function GuideService() {

    const navigate = useNavigate()

    const [openModal, setOpenModal] = useState(false)
    const [dataSocket, setDataSocket] = useState({})

    const dispatch = useDispatch()
    const authen = useSelector((state) => state.auth);

    const [openModalUsermanual, setOpenModalUsermanual] = useState(false)

    const [version, setVersion] = useState('')

    useEffect(() => {
        const socket = io(ENDPOINT_SOCKET, {
            autoConnect: true,
            reconnection: false,
            transports: ["websocket"]
        })

        // client-side
        socket.on("connect", () => {
            console.log('on : connect')
            console.log(socket.id);
            socket.emit('subscribe', 'frontend')
        });

        socket.on("disconnect", () => {
            console.log('on : disconnect')
            console.log(socket.id);
        });

        socket.on("connect_error", () => {
            console.log('on : connect_error');
            socket.connect();
        });

        socket.io.on("reconnect_attempt", () => {
            console.log('on : reconnect_attempt');
        });

        socket.io.on("reconnect", () => {
            console.log('on : reconnect');
        });

        socket.on("subscribed", (data) => {
            console.log('on : subscribed', data);
        });

        socket.on("unsubscribed", () => {
            console.log('on : unsubscribed');
        });

        socket.on("alert-warning", (data) => {
            console.log('on : alert-warning', data);

            //open modal
            setDataSocket(data)
            setOpenModal(true)
        });

        socket.on("card-inserted", (data) => {
            console.log('on : card-inserted ', data);

            const inform = JSON.stringify(data)
            navigate(`/consent?data=${inform}`)
        });

        socket.on("card-removed", (data) => {
            console.log('on : card-removed ', data);
        });

        socket.on("card-error", (data) => {
            console.log('on : card-error ', data);
        });

        socket.on("thai-d-auth", (data) => {
            console.log('on : thai-d-auth ', data);
        });

        socket.on("thai-d-reject", (data) => {
            console.log('on : thai-d-reject ', data);
        });

        socket.on("toasts", (data) => {
            console.log('on : toasts ', data);
            notifyInfo(data['message'])
        });

        return () => {
            socket.disconnect();
        };
    }, [])

    useEffect(() => {
        refreshToken(authen.user.RefreshToken)
    }, [])

    useEffect(() => {
        if (localStorage.getItem('version')) {
            setVersion(localStorage.getItem('version'))
        } else {
            setVersion('-')
        }
    }, [])



    const refreshToken = async (Retoken) => {
        console.log('RefreshToken -->', Retoken)
        let res = await api.refreshToken(Retoken)
        if (res) {
            if (res['data']['status'] == true) {
                dispatch(login(res['data']['data']))
                localStorage.setItem('access_token', res['data']['data']['AccessToken'])
            } else {
                notifyError(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    return (
        <div className='bg__health__atm'>
            {openModal ?
                <ModalAlertAuto
                    onClose={() => { setOpenModal(false) }}
                    header={dataSocket['message_header']}
                    title={dataSocket['message_body']}
                />
                : null
            }
            {openModalUsermanual ?
                <ModalUserManual
                    onClose={() => { setOpenModalUsermanual(false) }}
                />
                : null
            }
            <Box sx={{
                minHeight: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Box sx={{
                    width: '1000px',
                    height: 'auto',
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #E1E3E9',
                    boxShadow: '5px 5px 20px #E1E3E9',
                    borderRadius: '20px',
                    padding: '40px',
                    margin: '20px',
                    display: 'grid',
                    justifyItems: 'center',
                    gridGap: '40px',
                    '@media (max-width: 600px)': {
                        padding: '20px 10px',
                        gridGap: '20px',
                    }
                }}>
                    {/* <Box sx={{
                        display: 'flex',
                        marginLeft: 'auto',
                        marginTop: '-10px',
                        gridGap: '10px',
                        color: '#2CC2C0',
                        fontWeight: 600,
                        fontSize: '18px',
                        cursor: 'pointer',
                        '@media (max-width: 700px)': {
                            fontSize: '14px'
                        }
                    }} onClick={() => { setOpenModalUsermanual(true) }}>
                        <img src={warning} />
                        <div style={{ textDecoration: 'underline' }}>คู่มือการใช้งาน</div>
                    </Box> */}

                    <Box sx={{
                        width: '300px',
                        display: 'flex',
                        justifyContent: 'center',
                        gridGap: 20,
                        '@media (max-width: 600px)': {
                            width: '100px'
                        }
                    }}>
                        <img style={{ width: '80%' }} src={logo} />
                    </Box>

                    <Box sx={{
                        display: 'grid',
                        gridTemplateColumns: '4fr 1fr 3fr',
                        '@media (max-width: 800px)': {
                            display: 'grid',
                            gridTemplateColumns: '1fr',
                        }
                    }}>
                        <Box>
                            <Typography sx={{
                                fontSize: '26px',
                                fontWeight: 600,
                                display: 'grid',
                                justifyItems: 'center',
                                '@media (max-width: 1000px)': {
                                    fontSize: '30px',
                                },
                                '@media (max-width: 700px)': {
                                    fontSize: '18px',
                                }
                            }}>
                                <div>กรุณาเสียบบัตรประชาชนเพื่อเข้ารับบริการ</div>
                                <div>เอทีเอ็มสุขภาพ</div>
                            </Typography>
                            <Box sx={{
                                width: '400px',
                                margin: 'auto',
                                '@media (max-width: 600px)': {
                                    width: '200px'
                                }
                            }}>
                                <img style={{ width: '100%' }} src={card} />
                            </Box>
                        </Box>

                        <Box sx={{
                            borderLeft: '2px solid #E1E3E9',
                            margin: '0 30px',
                            '@media (max-width: 800px)': {
                                borderTop: '2px solid #E1E3E9',
                                margin: '30px 0',
                            }
                        }}></Box>

                        <Box>
                            <Typography sx={{
                                fontSize: '26px',
                                fontWeight: 600,
                                display: 'grid',
                                justifyItems: 'center',
                                '@media (max-width: 1000px)': {
                                    fontSize: '30px',
                                },
                                '@media (max-width: 700px)': {
                                    fontSize: '18px',
                                }
                            }}>
                                <div>สแกน QR Code ด้วย Thai ID</div>
                                <div>เพื่อเข้ารับบริการ เอทีเอ็มสุขภาพ</div>
                            </Typography>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '15%',
                                '@media (max-width: 800px)': {
                                    margin: '10% 0'
                                }
                            }}>
                                <Button
                                    variant="contained"
                                    sx={{ 
                                        borderRadius: '10px', 
                                        display: 'flex', 
                                        gridGap: '15px',
                                        '@media (max-width: 800px)': {
                                            width: '400px'
                                        },
                                        '@media (max-width: 600px)': {
                                            width: 'auto'
                                        }
                                    }}
                                    onClick={() => { window.shell.openThaiD() }}
                                >
                                    <img className="imgIcon" src={thaiID} />
                                    <Box sx={{
                                        fontSize: 18,
                                        '@media (max-width: 800px)': {
                                            fontSize: 16,
                                        }
                                    }}>สแกน QR Code ด้วย Thai ID</Box>
                                </Button>
                            </Box>
                        </Box>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        marginLeft: 'auto',
                        marginTop: '-10px',
                        gridGap: '10px',
                        fontSize: '16px',
                        '@media (max-width: 700px)': {
                            fontSize: '12px'
                        }
                    }} >
                        <Box>เวอร์ชัน : {version}</Box>
                    </Box>
                </Box>
            </Box>
        </div>
    )
}