import React, { useEffect, useRef, useState } from "react"
import { Alert, Box, Button, Typography } from "@mui/material"

import medical from '../assets/icons/medicaldata.svg'
import heart from '../assets/icons/heart.svg'
import person from '../assets/icons/person.svg'
import folder from '../assets/icons/folder.svg'
import video from '../assets/icons/video.svg'
import healthcheck from '../assets/icons/healthcheck.svg'
import healthstation from '../assets/icons/healthstation.svg'
import docfong from '../assets/icons/docfong.svg'
import lab from '../assets/icons/lab.svg'
import calendar from '../assets/icons/calendar.svg'
import call from '../assets/icons/consult.svg'
import docRefer from '../assets/icons/doc.svg'

import { useNavigate } from "react-router-dom"
import Loader from "../components/Loader"
import styles from './Login.module.css'
import ModalAlert from '../components/modal/ModalAlert'
import ModalAlertAuto from "../components/modal/ModalAlertAuto"
import { notifyInfo, notifyWarning } from '../components/NotifyToast'

import io from 'socket.io-client'
import { environment } from "../config.env";
import { CheckBox } from "@mui/icons-material"

import logo from '../assets/images/logo.svg'
import ModalUpgrade from "../components/modal/ModalUpgrade"

const ENDPOINT_SOCKET = environment.endpoint_socket

export default function MainMenu() {

    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    const [openModal, setOpenModal] = useState(false)
    const [userData, setUserData] = useState(null)

    const [dataSocket, setDataSocket] = useState({})
    const [openModalSocket, setOpenModalSocket] = useState(false)

    const [openModalUpgrade, setOpenModalUpgrade] = useState(false)

    let buttonKey = useRef(null);

    const menu = [
        {
            key: '1',
            value: 'ตรวจสอบประวัติ จากทุกโรงพยาบาล',
            image: medical,
            status: true
        },
        {
            key: '5',
            value: 'ตรวจสอบ ข้อมูลการส่งต่อ',
            image: folder,
            status: false
        },
        {
            key: '2',
            value: 'ตรวจสอบสิทธิ์',
            image: person,
            status: true
        },
        {
            key: '4',
            value: 'ผลตรวจสุขภาพ ประจำปี',
            image: healthcheck,
            status: true
        },
        {
            key: '6',
            value: 'ผลตรวจแลป นอกสถานที่',
            image: lab,
            status: true
        },
        {
            key: '7',
            value: 'คัดกรองสุขภาพ ผ่านอุปกรณ์ IoT',
            image: healthstation,
            status: true
        },
        {
            key: '10',
            value: 'รายการนัดหมาย',
            image: calendar,
            status: true
        },
        {
            key: '11',
            value: 'ปรึกษา',
            image: call,
            status: true
        },
        // {
        //     key: '12',
        //     value: 'เอกสารการส่งต่อ',
        //     image: docRefer,
        //     status: true
        // },

        // {
        //     key: '8',
        //     value: 'สรุปผลพฤติกรรม สุขภาพจาก อสม.',
        //     image: docfong,
        //     status: false
        // },
        // {
        //     key: '9',
        //     value: 'Telemedicine',
        //     image: video,
        //     status: false
        // }
        // {
        //     key: '3',
        //     value: 'สรุปผลสุขภาพ',
        //     image: heart,
        //     status: false
        // },
    ]

    useEffect(() => {
        const user = localStorage.getItem('userData')
        const data = JSON.parse(user)
        setUserData(data)
        console.log(data)
    }, [])

    useEffect(() => {
        const socket = io(ENDPOINT_SOCKET, {
            autoConnect: true,
            reconnection: false,
            transports: ["websocket"]
        })

        // client-side
        socket.on("connect", () => {
            console.log('on : connect')
            console.log(socket.id);
            socket.emit('subscribe', 'frontend')
        });

        socket.on("disconnect", () => {
            console.log('on : disconnect')
            console.log(socket.id);
        });

        socket.on("connect_error", () => {
            console.log('on : connect_error');
            socket.connect();
        });

        socket.io.on("reconnect_attempt", () => {
            console.log('on : reconnect_attempt');
        });

        socket.io.on("reconnect", () => {
            console.log('on : reconnect');
        });

        socket.on("subscribed", (data) => {
            console.log('on : subscribed', data);
        });

        socket.on("unsubscribed", () => {
            console.log('on : unsubscribed');
        });

        socket.on("alert-warning", (data) => {
            console.log('on : alert-warning', data);

            setDataSocket(data)
            //open modal
            setOpenModal(true)
        });

        socket.on("card-inserted", (data) => {
            console.log('on : card-inserted ', data);

            if (JSON.parse(localStorage.getItem('userData'))?.personal?.pid == data['personal']['pid']) {
                localStorage.setItem('userData', JSON.stringify(data))
                const user = localStorage.getItem('userData')
                const information = JSON.parse(user)
                setUserData(information)

                if (buttonKey.current === '2') {
                    if (information['personal']['right']) {
                        navigate('/checkPermissions')
                    }
                }
            } else {
                notifyWarning('เลขบัตรประชาชนไม่ตรง')
            }

        });

        socket.on("card-removed", (data) => {
            console.log('on : card-removed ', data);
        });

        socket.on("card-error", (data) => {
            console.log('on : card-error ', data);
        });

        socket.on("thai-d-auth", (data) => {
            console.log('on : thai-d-auth ', data);
        });

        socket.on("thai-d-reject", (data) => {
            console.log('on : thai-d-reject ', data);
        });

        socket.on("toasts", (data) => {
            console.log('on : toasts ', data);
            notifyInfo(data['message'])
        });

        return () => {
            socket.disconnect();
        };
    }, [])

    const clickMenu = (item, status) => {
        if (status === true) {
            if (item['key'] === '1') { //ประวัติการรักษา ในโรงพยาบาลเครือข่าย
                buttonKey.current = item['key']
                navigate(`/medicalInformationNew?PID=${JSON.parse(localStorage.getItem('userData'))?.personal?.pid}`)
            } else if (item['key'] === '2') { //ตรวจสอบสิทธิ์
                buttonKey.current = item['key']
                JSON.parse(localStorage.getItem('userData'))?.personal?.right ? navigate('/checkPermissions') : setOpenModal(true)
            } else if (item['key'] === '3') { //สรุปผลสุขภาพ

            } else if (item['key'] === '4') { //ผลตรวจสุขภาพ ประจำปี
                buttonKey.current = item['key']
                navigate('/healthResultsAnnual')
            } else if (item['key'] === '5') { //ตรวจสอบ ข้อมูลการส่งต่อ
                // navigate('/checkForwarding')
            } else if (item['key'] === '6') { //ผลตรวจแลป นอกสถานที่
                buttonKey.current = item['key']
                navigate(`/offsiteLabResults?PID=${JSON.parse(localStorage.getItem('userData'))?.personal?.pid}`)
            } else if (item['key'] === '7') { //คัดกรองสุขภาพ ผ่านอุปกรณ์ IoT
                buttonKey.current = item['key']
                navigate(`/healthHistory?PID=${JSON.parse(localStorage.getItem('userData'))?.personal?.pid}`)
            } else if (item['key'] === '8') { //สรุปผลพฤติกรรม สุขภาพจาก อสม.

            } else if (item['key'] === '9') { //Telemedicine

            } else if (item['key'] === '10') { //รายการนัดหมาย
                navigate(`/appointmentList?PID=${JSON.parse(localStorage.getItem('userData'))?.personal?.pid}`)
            } else if (item['key'] === '11') { //ปรึกษาหมอ
                navigate(`/consult`)
            } else if (item['key'] === '12') { //เอกสารการส่งต่อ
                // navigate(`/refer`)
            }
        } else {
            setOpenModalUpgrade(true)
        }
    }


    return (
        <div className='bg__health__atm'>
            {openModal ?
                <ModalAlert
                    detail={'กรุณาเสียบบัตรประชาชน'}
                    onClose={() => { setOpenModal(false) }}
                />
                : null
            }
            {openModalSocket ?
                <ModalAlertAuto
                    onClose={() => { setOpenModalSocket(false) }}
                    header={dataSocket['message_header']}
                    title={dataSocket['message_body']}
                />
                : null
            }
            {openModalUpgrade ?
                <ModalUpgrade
                    onClose={() => { setOpenModalUpgrade(false) }}
                />
                : null
            }

            <Loader loading={loading} />

            <Box sx={{
                minHeight: '100vh',
                display: 'grid',
                justifyContent: 'center',
                alignContent: 'center',
            }}>
                <Box sx={{ textAlign: 'center' }}>
                    <img src={logo} />
                </Box>

                <Box sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gridGap: '20px',
                    marginTop: '20px',
                    '@media (max-width: 850px)': {
                        gridGap: '15px',
                        gridTemplateColumns: '1fr',
                    }
                }}>
                    {menu.map((i, idx) => (
                        <Box key={idx}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                background: i['status'] ? '#2CC2C0' : '#DBDBDB',
                                borderRadius: '10px',
                                padding: '15px 70px',
                                cursor: 'pointer',
                                '@media (max-width: 1024px)': {
                                    padding: '15px 20px',
                                },
                                '@media (max-width: 700px)': {
                                    width: '350px',
                                    padding: '15px',
                                }
                            }} onClick={() => { clickMenu(i, i['status']) }}>
                                <Box sx={{
                                    display: 'flex',
                                    gridGap: '20px',
                                    color: '#FFFFFF',
                                    fontSize: '22px',
                                    '@media (max-width: 700px)': {
                                        fontSize: '14px'
                                    }
                                }}>
                                    <img style={{ width: '25px' }} src={i['image']} />
                                    <div>{i['value']}</div>
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </Box>

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gridTemplateColumns: '1fr 1fr 1fr',
                    gridGap: '30px',
                    marginTop: '10%',
                    '@media (max-width: 700px)': {
                        gridTemplateColumns: '1fr'
                    }
                }}>
                    <Box sx={{ width: '100%' }}></Box>
                    <Button variant="contained"
                        sx={{
                            width: '150%',
                            height: '50px',
                            borderRadius: '30px',
                            fontSize: '20px',
                            '@media (max-width: 700px)': {
                                width: '120%',
                                height: '40px',
                                fontSize: '14px',
                            }
                        }}
                        onClick={() => {
                            localStorage.removeItem('userData')
                            navigate('/guideService')
                        }}
                    >เสร็จสิ้น</Button>
                    <Box sx={{ width: '100%' }}></Box>
                </Box>

            </Box>

        </div>
    )
}