import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from './HealthStyle.module.css'
import VitalSigns from './VitalSigns'
import { useLocation, useNavigate } from "react-router-dom";
import backIcon from '../../assets/icons/backicon.svg'
import Loader from "../../components/Loader";
import * as api from '../../service/api'
import { notifyError } from '../../components/NotifyToast'

import sugar from '../../assets/images/machineBloodSuger.svg'
import blood from '../../assets/images/machineBlood.svg'
import weigth from '../../assets/images/machineWeight.svg'
import oxygen from '../../assets/images/machineOxygen.svg'

import _ from 'lodash'

export default function HealthHistory() {

    const navigate = useNavigate()
    const location = useLocation()
    const [value, setValue] = useState(0);
    const pid = new URLSearchParams(location.search).get('PID')

    return (
        <Box sx={{
            display: 'grid',
            gridGap: 20,
            fontSize: 18,
            margin: { xl: '2rem', lg: '2rem', md: '2rem', sm: '2rem', xs: '2rem 1rem' },
            '@media (max-width: 1023px)': {
                gridGap: '3%',
                fontSize: 16,
            },
            '@media (max-width: 800px)': {
                gridGap: '1%',
                fontSize: 14,
            }
        }} >
            <Box sx={{ display: 'flex', gridGap: 10, cursor: 'pointer' }} onClick={() => { navigate('/mainMenu') }}>
                <img src={backIcon} />
                <div>ย้อนกลับ</div>
            </Box>
            <Box sx={{
                textAlign: 'center',
                color: '#2CC2C0',
                fontSize: '24px'
            }}
            >ผลการคัดกรองสุขภาพผ่านอุปกรณ์ IOT</Box>

            {/* <Box sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                fontSize: 18,
                marginTop: 5,
                marginLeft: 5,
                marginRight: 5,
                '@media (max-width: 1023px)': {
                    fontSize: 16,
                },
                '@media (max-width: 800px)': {
                    gridTemplateColumns: '1fr',
                    fontSize: 14,
                }
            }}>
                <div onClick={() => { setValue(0) }} className={value === 0 ? styles.btGreen : styles.btGay} >ประวัติค่าสัญญาณชีพ</div>
                <div onClick={() => { setValue(1) }} className={value === 1 ? styles.btGreen : styles.btGay} >วัดค่าสัญญาณชีพ</div>
            </Box> */}

            {value === 0 ?
                <HistoryVitalSigns
                    PID={pid}
                />
                : null
            }
            {/* {value === 1 ?
                <VitalSigns />
                : null
            } */}

        </Box>
    )
}

const HistoryVitalSigns = ({ PID }) => {

    const [loading, setLoading] = useState(false)
    const [measureResult, setMeasureResult] = useState([])

    useEffect(() => {
        findMeasureResult(PID)
    }, [])

    const split = (id) => {
        let splt = id.split(" ")
        return splt[0]
    }

    const findMeasureResult = async (pid) => {
        setLoading(true)
        let res = await api.findMeasureResult(pid)
        setLoading(false)
        if (res) {
            console.log(res['data'])
            let raw = res['data']
            let date_list = raw.bloodpressure.map(item => ({ collected: split(item.collected), bloodpressure: [], glucose: [], weight: [], oxygen: [] }));
            date_list = date_list.concat(raw.glucose.map(item => ({ collected: split(item.collected), bloodpressure: [], glucose: [], weight: [], oxygen: [] })));
            date_list = date_list.concat(raw.weight.map(item => ({ collected: split(item.collected), bloodpressure: [], glucose: [], weight: [], oxygen: [] })));
            date_list = date_list.concat(raw.oxygen.map(item => ({ collected: split(item.collected), bloodpressure: [], glucose: [], weight: [], oxygen: [] })));
            date_list = _.orderBy(_.uniqBy(date_list, 'collected'), ['collected'], ['desc'])

            date_list.forEach((item_) => {
                item_.bloodpressure = raw.bloodpressure.filter(item => split(item.collected) === item_.collected)
                item_.glucose = raw.glucose.filter(item => split(item.collected) === item_.collected)
                item_.weight = raw.weight.filter(item => split(item.collected) === item_.collected)
                item_.oxygen = raw.oxygen.filter(item => split(item.collected) === item_.collected)
            })
            console.log(date_list)
            setMeasureResult(date_list)
        } else {
            notifyError('ไม่ตอบสนอง')
        }

        // let raw = {
        //     "bloodpressure": [
        //         {
        //             "id": 7,
        //             "pulse": 70,
        //             "sys": 140,
        //             "dia": 80,
        //             "ihb": 0,
        //             "mov": 0,
        //             "device": "Omron_BP_H6000",
        //             "gw_id": null,
        //             "collected": "2023-10-10 10:44:46"
        //         }
        //     ],
        //     "glucose": [
        //         {
        //             "id": 7,
        //             "glucose": 150,
        //             "seq": 1,
        //             "device": "Accu",
        //             "gw_id": null,
        //             "collected": "2023-10-10 10:33:59"
        //         }
        //     ],
        //     "weight": [
        //         {
        //             "id": 7,
        //             "weight": 50,
        //             "device": "Omron_weight",
        //             "gw_id": null,
        //             "collected": "2023-10-10 10:37:38"
        //         }
        //     ],
        //     "oxygen": [
        //         {
        //             "id": 7,
        //             "spo2": 94,
        //             "bpm": 70,
        //             "pi": 5,
        //             "device": "Omi_spo2",
        //             "gw_id": null,
        //             "collected": "2023-10-10 10:31:36"
        //         }
        //     ],
        //     "temperature": [
        //         {
        //             "id": 7,
        //             "temperature": 37,
        //             "device": "ThermoMeter",
        //             "gw_id": null,
        //             "collected": "2023-10-10 10:27:16"
        //         },
        //         {
        //             "id": 12,
        //             "temperature": 37,
        //             "device": "ThermoMeter",
        //             "gw_id": null,
        //             "collected": "2023-10-11 15:54:46"
        //         }
        //     ]
        // }

        // let date_list = raw.bloodpressure.map(item => ({ collected: split(item.collected), bloodpressure: [], glucose: [], weight: [], oxygen: [] }));
        // date_list = date_list.concat(raw.glucose.map(item => ({ collected: split(item.collected), bloodpressure: [], glucose: [], weight: [], oxygen: [] })));
        // date_list = date_list.concat(raw.weight.map(item => ({ collected: split(item.collected), bloodpressure: [], glucose: [], weight: [], oxygen: [] })));
        // date_list = date_list.concat(raw.oxygen.map(item => ({ collected: split(item.collected), bloodpressure: [], glucose: [], weight: [], oxygen: [] })));

        // date_list = _.orderBy(_.uniqBy(date_list, 'collected'), ['collected'], ['desc'])

        // date_list.forEach((item_) => {
        //     item_.bloodpressure = raw.bloodpressure.filter(item => split(item.collected) === item_.collected)
        //     item_.glucose = raw.glucose.filter(item => split(item.collected) === item_.collected)
        //     item_.weight = raw.weight.filter(item => split(item.collected) === item_.collected)
        //     item_.oxygen = raw.oxygen.filter(item => split(item.collected) === item_.collected)
        // })
        // console.log(date_list)
        // setMeasureResult(date_list)

    }

    const convertDate = (dataDate) => {
        const date = new Date(dataDate)
        const optionDate = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timeZone: 'UTC',
        }

        const thaiLocale = 'th-TH';
        const formattedDate = date.toLocaleDateString(thaiLocale, optionDate)

        const newDate = `${formattedDate}`
        return newDate
    }

    // const translateHeader = (name) => {
    //     if (`${name}`.toLowerCase() === 'scale-meter'.toLowerCase()) {
    //         return <img src={weigth} />
    //     } else if (`${name}`.toLowerCase() === 'bp-meter'.toLowerCase()) {
    //         return <img src={blood} />
    //     } else if (`${name}`.toLowerCase() === 'oxi-meter'.toLowerCase()) {
    //         return <img src={oxygen} />
    //     } else if (`${name}`.toLowerCase() === 'glucose-meter'.toLowerCase()) {
    //         return <img src={sugar} />
    //     } else {
    //         return name
    //     }
    // }

    // const translate = (name) => {
    //     if (`${name}`.toLowerCase() === 'Weight'.toLowerCase()) {
    //         return 'น้ำหนัก (Weight)'
    //     } else if (`${name}`.toLowerCase() === 'BloodPressure'.toLowerCase()) {
    //         return 'ค่าความดันโลหิต (SYS/DIA)'
    //     } else if (`${name}`.toLowerCase() === 'PulseRate'.toLowerCase()) {
    //         return 'อัตราการเต้นของหัวใจ (BPM)'
    //     } else if (`${name}`.toLowerCase() === 'Oxygen'.toLowerCase()) {
    //         return 'ค่าออกซิเจนในเลือด (SpO2)'
    //     } else if (`${name}`.toLowerCase() === 'Glucose'.toLowerCase()) {
    //         return 'ระดับน้ำตาลในเลือด (Blood sugar)'
    //     } else {
    //         return name
    //     }
    // }

    return (
        <>
            <Loader loading={loading} />
            <Box sx={{
                color: '#263238',
                display: 'grid',
                gridGap: 20,
                fontSize: 18,
                '@media (max-width: 1023px)': {
                    gridGap: '3%',
                    fontSize: 16,
                },
                '@media (max-width: 800px)': {
                    gridGap: '2%',
                    fontSize: 14,
                }
            }}>
                {measureResult.length > 0 ?
                    <Box sx={{
                        display: 'flex',
                        gridGap: 15,
                        overflow: "hidden",
                        overflowX: "scroll",
                        marginBottom: '1rem',
                    }}>
                        {measureResult.map((i, idx) => (
                            <Box sx={{
                                minWidth: '450px',
                                backgroundColor: '#E1F4EB',
                                borderRadius: 3,
                                padding: 3,
                                maxWidth: '300px',
                                overflowY: 'auto',
                                // minHeight: 'auto',
                                maxHeight: '700px',
                                marginBottom: '1rem',
                                '@media (max-width: 1024px)': {
                                    minWidth: '350px',
                                    padding: 1,
                                },
                                '@media (max-width: 800px)': {
                                    minWidth: '300px',
                                    padding: 1,
                                }
                            }} key={idx}>
                                <Box sx={{
                                    fontWeight: 700,
                                    color: '#2CC2C0',
                                    fontSize: 24,
                                    '@media (max-width: 1024px)': {
                                        fontSize: 20
                                    }
                                }}>{convertDate(i['collected'])}</Box>
                                <Box sx={{
                                    display: 'grid',
                                    gridGap: 15,
                                    '@media (max-width: 1023px)': {
                                        padding: 1,
                                        gridGap: 15,
                                    },
                                    '@media (max-width: 800px)': {
                                        gridGap: 8,
                                    }
                                }}>
                                    {/* {i['MeasureList'].map((item, index) => (
                                        <Box sx={{
                                            display: 'flex',
                                            backgroundColor: '#FFFFFF',
                                            borderRadius: 2,
                                            padding: 2
                                        }} key={index}>
                                            <Box>{translateHeader(item['MeasureDevice'])}</Box>
                                            <Box>
                                                {item['MeasureGroup'].map((i, idx) => (
                                                    <Box key={idx} sx={{ padding: '10px 30px' }}>
                                                        <Box sx={{ fontWeight: 700, color: '#2CC2C0' }}>{translate(i['MeasureName'])}</Box>
                                                        <Box sx={{ padding: '0 10px' }}>{i['MeasureName'] === 'BloodPressure' ? i['MeasureValue'] + ' ' + 'mmHg' : i['MeasureValue'] + ' ' + i['MeasureUnit']}</Box>
                                                    </Box>
                                                ))}
                                            </Box>
                                        </Box>
                                    ))} */}

                                    {i['glucose'] ?
                                        <>
                                            {i['glucose'].map((i, idx) => (
                                                <Box sx={{
                                                    display: 'flex',
                                                    backgroundColor: '#FFFFFF',
                                                    borderRadius: 2,
                                                    padding: 2
                                                }}>
                                                    <Box>
                                                        <img src={sugar} />
                                                    </Box>
                                                    <Box>
                                                        <Box key={idx} sx={{ padding: '10px 30px' }}>
                                                            <Box sx={{ fontWeight: 700, color: '#2CC2C0' }}>ระดับน้ำตาลในเลือด (Blood sugar) </Box>
                                                            <Box sx={{ padding: '0 10px' }}>{i['glucose'] ? i['glucose'] + ' mg/dL' : '-'}</Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            ))}
                                        </>
                                        : null
                                    }

                                    {i['weight'] ?
                                        <>
                                            {i['weight'].map((i, idx) => (
                                                <Box sx={{
                                                    display: 'flex',
                                                    backgroundColor: '#FFFFFF',
                                                    borderRadius: 2,
                                                    padding: 2
                                                }}>
                                                    <Box>
                                                        <img src={weigth} />
                                                    </Box>
                                                    <Box>
                                                        <Box key={idx} sx={{ padding: '10px 30px' }}>
                                                            <Box sx={{ fontWeight: 700, color: '#2CC2C0' }}>น้ำหนัก (Weight) </Box>
                                                            <Box sx={{ padding: '0 10px' }}>{i['weight'] ? i['weight'] + ' kg' : '-'}</Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            ))}
                                        </>
                                        : null
                                    }

                                    {i['bloodpressure'] ?
                                        <>
                                            {i['bloodpressure'].map((i, idx) => (
                                                <Box sx={{
                                                    display: 'flex',
                                                    backgroundColor: '#FFFFFF',
                                                    borderRadius: 2,
                                                    padding: 2
                                                }} key={idx}>
                                                    <Box>
                                                        <img src={blood} />
                                                    </Box>
                                                    <Box>
                                                        <Box sx={{ padding: '10px 30px' }}>
                                                            <Box sx={{ fontWeight: 700, color: '#2CC2C0' }}>ค่าความดันโลหิตตัวบน (SYS)</Box>
                                                            <Box sx={{ padding: '0 10px' }}>{i['sys'] ? i['sys'] + ' mmHg' : '-'}</Box>
                                                        </Box>
                                                        <Box sx={{ padding: '10px 30px' }}>
                                                            <Box sx={{ fontWeight: 700, color: '#2CC2C0' }}>ค่าความดันโลหิตตัวล่าง (DIA)</Box>
                                                            <Box sx={{ padding: '0 10px' }}>{i['dia'] ? i['dia'] + ' mmHg' : '-'}</Box>
                                                        </Box>
                                                        <Box sx={{ padding: '10px 30px' }}>
                                                            <Box sx={{ fontWeight: 700, color: '#2CC2C0' }}>อัตราการเต้นของหัวใจ (BPM)</Box>
                                                            <Box sx={{ padding: '0 10px' }}>{i['pulse'] ? i['pulse'] + ' /min' : '-'}</Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            ))}
                                        </>
                                        : null
                                    }

                                    {i['oxygen'] ?
                                        <>
                                            {i['oxygen'].map((i, idx) => (
                                                <Box sx={{
                                                    display: 'flex',
                                                    backgroundColor: '#FFFFFF',
                                                    borderRadius: 2,
                                                    padding: 2
                                                }} key={idx}>
                                                    <Box>
                                                        <img src={oxygen} />
                                                    </Box>
                                                    <Box>
                                                        <Box sx={{ padding: '10px 30px' }}>
                                                            <Box sx={{ fontWeight: 700, color: '#2CC2C0' }}>ค่าออกซิเจนในเลือด (SpO2) </Box>
                                                            <Box sx={{ padding: '0 10px' }}>{i['spo2'] ? i['spo2'] + ' %' : '-'}</Box>
                                                        </Box>
                                                        <Box sx={{ padding: '10px 30px' }}>
                                                            <Box sx={{ fontWeight: 700, color: '#2CC2C0' }}>อัตราการเต้นของหัวใจ (BPM) </Box>
                                                            <Box sx={{ padding: '0 10px' }}>{i['bpm'] ? i['bpm'] + ' /min' : '-'}</Box>
                                                        </Box>
                                                        <Box sx={{ padding: '10px 30px' }}>
                                                            <Box sx={{ fontWeight: 700, color: '#2CC2C0' }}>การไหลเวียนของเลือด (PI) </Box>
                                                            <Box sx={{ padding: '0 10px' }}>{i['pi'] ? i['pi'] + ' %' : '-'}</Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            ))}
                                        </>
                                        : null
                                    }

                                </Box>
                            </Box>
                        ))}

                    </Box>
                    :
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '10%',
                        fontSize: '20px',
                        '@media (max-width: 800px)': {
                            marginTop: '10%',
                            fontSize: '16px',
                        }
                    }}>ไม่พบข้อมูล</Box>
                }
            </Box >
        </>
    )
}