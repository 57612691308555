import React, { useEffect, useState } from "react"
import styles from './Login.module.css'
import logo from '../assets/images/logo.svg'
import logoMain from '../assets/images/logoPeople.svg'
import { Box, Button, TextField, Typography } from "@mui/material"
import * as api from '../service/api'
import { useLocation, useNavigate } from "react-router-dom"
import Loader from "../components/Loader"
import { notifyError, notifyWarning } from "../components/NotifyToast"
import { useDispatch } from "react-redux"
import { login as loginReduce, logout } from '../store/authSlice'
import ModalAlert from '../components/modal/ModalAlert'

export default function Login() {

    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    const [username, setusername] = useState('')
    const [password, setPassword] = useState('')

    const [version, setVersion] = useState('')

    const [openModal, setOpenModal] = useState(false)

    useEffect(() => {
        let ver = new URLSearchParams(window.location.search).get('version')

        if (ver) {
            localStorage.setItem('version', ver)
            setVersion(ver)
        } else {
            if (localStorage.getItem('version')) {
                setVersion(localStorage.getItem('version'))
            } else {
                setVersion('-')
            }
        }

    }, [])


    const login = async (username, password) => {
        setLoading(true)
        let res = await api.login(username, password)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                localStorage.setItem('access_token', res['data']['data']['AccessToken'])
                profile(res['data']['data'])
            } else {
                notifyError(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const profile = async (inform) => {
        setLoading(true)
        let res = await api.profile()
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                if (res['data']['data']['UserType'] === 'TYPE2') {
                    dispatch(loginReduce(inform))
                    navigate('/guideService')
                } else {
                    setOpenModal(true)
                    setTimeout(() => {
                        localStorage.clear()
                        dispatch(logout())
                    }, 1000);
                }
            } else {
                notifyError(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const onClickLogin = () => {
        if (!username) {
            notifyWarning('กรุณากรอกอีเมล')
            return
        }
        if (!password) {
            notifyWarning('กรุณากรอกรหัสผ่าน')
            return
        }
        login(username, password)
    }

    return (
        <div className='bg__health__atm'>
            {openModal ?
                <ModalAlert
                    detail={'ไม่มีสิทธิ์เข้าใช้งานระบบ'}
                    onClose={() => { setOpenModal(false) }}
                />
                : null
            }
            <Loader loading={loading} />
            <Box sx={{
                minHeight: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <div>
                    <Box sx={{
                        color: '#263238',
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        gridGap: '40px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        justifyItems: 'center',
                        '@media (max-width: 1024px)': {
                            padding: '20px'
                        },
                        '@media (max-width: 800px)': {
                            gridTemplateColumns: '1fr'
                        }
                    }}>
                        <Box>
                            <img className={styles.iconLogo} src={logoMain} />
                        </Box>
                        <div style={{ margin: '20px 0' }} className={styles.card}>
                            <div className={styles.logo}>
                                <img src={logo} />
                            </div>
                            <div>
                                <Typography sx={{
                                    fontWeight: 700,
                                    fontSize: 18,
                                    '@media (max-width: 800px)': {
                                        fontSize: 16,
                                    }
                                }}>อีเมล</Typography>
                                <TextField
                                    type="email"
                                    size="small"
                                    sx={{ width: '100%' }}
                                    placeholder="กรุณาระบุอีเมลที่ลงทะเบียน"
                                    value={username}
                                    onChange={(event) => {
                                        const {
                                            target: { value }
                                        } = event;
                                        setusername(value)
                                    }}
                                />
                            </div>
                            <div>
                                <Typography sx={{
                                    fontWeight: 700,
                                    fontSize: 18,
                                    '@media (max-width: 800px)': {
                                        fontSize: 16,
                                    }
                                }}>รหัสผ่าน</Typography>
                                <TextField
                                    type="password" size="small"
                                    sx={{ width: '100%' }}
                                    placeholder="กรุณาระบุรหัสผ่านที่ลงทะเบียน"
                                    value={password}
                                    onChange={(event) => {
                                        const {
                                            target: { value }
                                        } = event;
                                        setPassword(value)
                                    }}
                                />
                            </div>
                            <Button
                                sx={{ width: '100%', cursor: 'pointer' }}
                                variant="contained"
                                onClick={onClickLogin}
                            >เข้าสู่ระบบ</Button>
                            <Typography sx={{
                                fontWeight: 700,
                                fontSize: 18,
                                '@media (max-width: 800px)': {
                                    fontSize: 16,
                                }
                            }}>***เข้าสู่ระบบด้วยผู้ใช้งานระดับแพทย์/พยาบาลเท่านั้น***</Typography>
                        </div>
                    </Box>
                </div>
                <Box>
                    <div className={styles.relative}>เวอร์ชัน : {version}</div>
                </Box>
            </Box>

        </div>
    )
}