import { Box, Typography } from '@mui/material'
import React from 'react'
import logoUp from '../assets/images/upgradeVersion.svg'
import backIcon from '../assets/icons/backicon.svg'
import logo from '../assets/images/logo.svg'
import { useNavigate } from 'react-router-dom'
import QRCode from 'react-qr-code'

export default function PayServiceFee() {

    const navigate = useNavigate()

    return (
        <div className='bg__health__atm'>
            <Box sx={{
                display: 'grid',
                gridGap: 20,
                fontSize: 18,
                padding: { xl: '2rem', lg: '2rem', md: '2rem', sm: '2rem', xs: '2rem 1rem' },
                '@media (max-width: 1023px)': {
                    gridGap: '3%',
                    fontSize: 16,
                },
                '@media (max-width: 800px)': {
                    gridGap: '1%',
                    fontSize: 14,
                }
            }}>
                <Box
                    sx={{ display: 'flex', gridGap: 10, cursor: 'pointer' }}
                    onClick={() => { navigate('/mainMenu') }}
                >
                    <img src={backIcon} />
                    <Typography>ย้อนกลับ</Typography>
                </Box>

                <Box sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    justifyItems: 'center',
                    padding: '70px',
                    '@media (max-width: 1024px)': {
                        padding: '20px'
                    },
                    '@media (max-width: 800px)': {
                        
                    }
                }}>
                    <img src={logoUp} />
                    <Box sx={{
                        display: 'grid',
                        justifyItems: 'center',
                        border: '1px solid #E1E3E9',
                        borderRadius: '20px',
                        background: '#FFFFFF',
                        padding: '20px',
                        width: '500px',
                        gridGap: '20px',
                        '@media (max-width: 1024px)': {
                            width: '400px'
                        },
                        '@media (max-width: 800px)': {
                            
                        }
                    }}>
                        <img style={{ width: '150px' }} src={logo} />
                        <QRCode
                            value=''
                            size={100}
                        />
                        <Typography sx={{fontSize: '18px'}}>ยอดรวมราคา 4,000 บาท</Typography>
                        <Typography>กรุณาสแกน QR Code เพื่อชำระเงิน</Typography>
                    </Box>
                </Box>
            </Box>

        </div>
    )
}
