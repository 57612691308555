import React, { useEffect, useState } from "react";
import * as api from '../../service/api'
import { Box, Button, FormControl, MenuItem, Pagination, Select } from "@mui/material";
import moment from "moment";
import CircleIcon from '@mui/icons-material/Circle';
import _ from 'lodash'
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import backIcon from '../../assets/icons/backicon.svg'
import { notifyError } from '../../components/NotifyToast'
import styles from './MedicalInformation.module.css'

export default function MedicalInformationNew() {

    const location = useLocation()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState(0);

    const [hospital, setHospital] = useState('')
    const [optionHospital, setOptionHospital] = useState([])
    const [date, setDate] = useState('')
    const [optionDate, setOptionDate] = useState([])

    const [dataInformation, setDataInformation] = useState([])
    const [dataPersonal, setDataPersonal] = useState([])

    const [dataAll, setDataAll] = useState([])

    const [page, setPage] = useState(1)
    const [maxPage, setMaxPage] = useState(1)
    const [showList, setShowList] = useState([])

    const [pid, setPid] = useState(new URLSearchParams(location.search).get('PID'))

    const [menuSelected, setMenuSelected] = useState();

    useEffect(() => {
        findInformation()
    }, [])

    useEffect(() => {
        setDate('')
        if (hospital === ' ' || hospital === '') {
            findInformation()
        } else {
            filterDataHospital()
        }
    }, [hospital])

    useEffect(() => {
        if (hospital === ' ' || hospital === '') {
            if (date === ' ' || date === '') {
                findInformation()
            } else {
                filterDataInformationDate()
            }
        } else {
            if (date === ' ' || date === '') {
                filterDataHospital()
            } else {
                filterDataInformationDate()
            }
        }
    }, [date])

    const findInformation = async () => {
        setLoading(true)
        let res = await api.findInformationNew(pid)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                //ข้อมูลส่วนตัว
                setDataPersonal(res['data']['data']['personal_information_list'])
                console.log(res['data']['data']['personal_information_list'])
                setMaxPage((Math.ceil(res['data']['data']['personal_information_list'].length / 1)))

                // เรียงข้อมูลแยกตามวันที่
                let raw = res['data']
                let date_list = raw.data.visit_information_list.map(item => ({ hos_id: item.hos_id, visit_date: item.visit_date, visit_information_list: [], labs_information_list: [], diagnosis_information_list: [], order_information_list: [] }));
                date_list = date_list.concat(raw.data.labs_information_list.map(item => ({ hos_id: item.hos_id, visit_date: item.visit_date, visit_information_list: [], labs_information_list: [], diagnosis_information_list: [], order_information_list: [] })));
                date_list = date_list.concat(raw.data.diagnosis_information_list.map(item => ({ hos_id: item.hos_id, visit_date: item.visit_date, visit_information_list: [], labs_information_list: [], diagnosis_information_list: [], order_information_list: [] })));
                date_list = date_list.concat(raw.data.order_information_list.map(item => ({ hos_id: item.hos_id, visit_date: item.visit_date, visit_information_list: [], labs_information_list: [], diagnosis_information_list: [], order_information_list: [] })));
                date_list = _.orderBy(_.uniqBy(date_list, 'visit_date'), ['visit_date'], ['desc'])

                date_list.forEach((item_) => {
                    item_.visit_information_list = raw.data.visit_information_list.filter(item => item.visit_date === item_.visit_date)
                    item_.labs_information_list = raw.data.labs_information_list.filter(item => item.visit_date === item_.visit_date)
                    item_.diagnosis_information_list = raw.data.diagnosis_information_list.filter(item => item.visit_date === item_.visit_date)
                    item_.order_information_list = raw.data.order_information_list.filter(item => item.visit_date === item_.visit_date)
                })
                setDataInformation(date_list)
                setDataAll(date_list)
                // console.log(date_list)

                setMenuSelected(Array(date_list.length).fill(0))
                //option date
                const List = []
                for (let i = 0; i < date_list.length; i++) {
                    if (date_list[i]) {
                        List.push(date_list[i]['visit_date'])
                    }
                }
                setOptionDate(List)

                //option hospital
                filterHospitalOption(res['data']['data'])

            } else {
                notifyError(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    useEffect(() => {
        let start = page * 1 - 1
        setShowList(dataPersonal.slice(start, start + 1))
    }, [page, dataPersonal])

    const handlePageChange = (event, page) => {
        setPage(page)
    }

    const filterHospitalOption = (data) => {
        let option = []
        for (let i = 0; i < data['personal_information_list'].length; i++) {
            if (data['personal_information_list'][i]) {
                option.push({
                    key: data['personal_information_list'][i]['hos_id'],
                    value: data['personal_information_list'][i]['hos_name']
                })
            }
        }
        const unique = [...new Map(option.map((m) => [m.key, m])).values()] //กรองชื่อซ้ำ
        setOptionHospital(unique)
        // console.log(unique)
    }

    const filterDataInformationDate = () => {
        const List = []
        for (let i = 0; i < dataAll.length; i++) {
            if (dataAll[i]['visit_date'] === date) {
                List.push(dataAll[i])
            }
        }
        setDataInformation(List)
        // console.log(List)
    }

    const filterDataHospital = () => {
        // console.log(dataAll)
        setDate('')

        const personal = []
        for (let i = 0; i < dataPersonal.length; i++) {
            if (dataPersonal[i]['hos_id'] === hospital) {
                personal.push(dataPersonal[i])
            }
        }
        setShowList(personal)
        setPage(1)
        setMaxPage((Math.ceil(personal.length / 1)))

        const List = []
        for (let i = 0; i < dataAll.length; i++) {
            if (dataAll[i]['hos_id'] === hospital) {
                List.push(dataAll[i])
            }
        }
        setDataInformation(List)
        console.log(List)

        const Date = []
        for (let i = 0; i < List.length; i++) {
            if (List[i]) {
                Date.push(List[i]['visit_date'])
            }
        }
        setOptionDate(Date)
    }

    const convertDate = (dataDate) => {
        const date = new Date(dataDate)
        const optionDate = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timeZone: 'UTC',
        }

        const thaiLocale = 'th-TH';
        const formattedDate = date.toLocaleDateString(thaiLocale, optionDate)

        const newDate = `${formattedDate}`
        return newDate
    }

    const dataSetMenu = (index, value) => {
        let tmp = menuSelected;
        if (menuSelected.length != dataInformation.length) {
            tmp = Array(dataInformation.length).fill(0);
        }

        tmp[index] = value;
        setMenuSelected([...tmp]);
    }

    console.log(dataInformation)

    return (
        <div>
            <Loader loading={loading} />
            <Box sx={{
                color: '#263238',
                display: 'grid',
                gridGap: 20,
                fontSize: 18,
                margin: { xl: '2rem', lg: '2rem', md: '2rem', sm: '2rem', xs: '2rem 1rem' },
                '@media (max-width: 1023px)': {
                    fontSize: 16,
                },
                '@media (max-width: 800px)': {
                    fontSize: 14,
                }
            }}>
                <Box sx={{ display: 'flex', gridGap: 10, cursor: 'pointer' }} onClick={() => { navigate('/mainMenu') }}>
                    <img src={backIcon} />
                    <div>ย้อนกลับ</div>
                </Box>
                <Box sx={{
                    textAlign: 'center',
                    color: '#2CC2C0',
                    fontSize: '24px'
                }}
                >ข้อมูลทางการแพทย์</Box>

                {value === 1 &&
                    <>
                        {dataPersonal ?
                            <Box sx={{
                                border: 1,
                                borderRadius: '10px',
                                borderColor: '#E1E3E9',
                                bgcolor: '#FFF',
                            }}>
                                <Box sx={{
                                    color: '#2CC2C0',
                                    fontWeight: 700,
                                    backgroundColor: '#E1F4EB',
                                    borderBottom: '1px solid #E1E3E9',
                                    borderRadius: '10px',
                                    padding: '10px 20px'
                                }}>ข้อมูลส่วนตัว</Box>

                                <Box sx={{
                                    display: 'grid',
                                    gridTemplateColumns: '1fr 1fr 1fr 1fr',
                                    gridGap: '20px',
                                    padding: '20px',
                                    '@media (max-width: 1023px)': {
                                        gridTemplateColumns: '1fr 1fr',
                                        gridGap: '15px',
                                        padding: '15px',
                                    },
                                    '@media (max-width: 800px)': {
                                        gridTemplateColumns: '1fr',
                                        gridGap: '10px',
                                        padding: '10px',
                                    }
                                }}>
                                    <div>
                                        <div style={{ fontWeight: 700, color: '#2CC2C0' }}>ชื่อ-นามสกุล</div>
                                        <div>{dataPersonal[0]?.['pname'] + dataPersonal[0]?.['fname'] + ' ' + dataPersonal[0]?.['lname']}</div>
                                    </div>
                                    <div>
                                        <div style={{ fontWeight: 700, color: '#2CC2C0' }}>ที่อยู่</div>
                                        <div>{dataPersonal[0]?.['address']['full_address']}</div>
                                    </div>
                                    <div>
                                        <div style={{ fontWeight: 700, color: '#2CC2C0' }}>เลขประจำตัวประชาชน</div>
                                        <div>{dataPersonal[0]?.['pid']}</div>
                                    </div>
                                    <div>
                                        <div style={{ fontWeight: 700, color: '#2CC2C0' }}>โทรศัพท์</div>
                                        <div>{dataPersonal[0]?.['home_phone'] || '-'}</div>
                                    </div>
                                    <div>
                                        <div style={{ fontWeight: 700, color: '#2CC2C0' }}>วันเกิด</div>
                                        <div>{convertDate(dataPersonal[0]?.['birthday'])}</div>
                                    </div>
                                    <div>
                                        <div style={{ fontWeight: 700, color: '#2CC2C0' }}>โทรศัพท์มือถือ</div>
                                        <div>{dataPersonal[0]?.['phone_number'] || '-'}</div>
                                    </div>
                                    <div>
                                        <div style={{ fontWeight: 700, color: '#2CC2C0' }}>อายุ</div>
                                        <div>{dataPersonal[0]?.['age'] + ' ปี'}</div>
                                    </div>
                                </Box>
                            </Box>
                            : null
                        }
                    </>
                }


                {value === 0 && showList.map((i, idx) => (
                    <>
                        <Box sx={{
                            border: 1,
                            borderRadius: '10px',
                            borderColor: '#E1E3E9',
                            bgcolor: '#FFF',
                        }}>
                            <Box sx={{
                                color: '#2CC2C0',
                                fontWeight: 700,
                                backgroundColor: '#E1F4EB',
                                borderBottom: '1px solid #E1E3E9',
                                borderRadius: '10px',
                                padding: '10px 20px'
                            }}>ข้อมูลส่วนตัว</Box>

                            <Box sx={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr 1fr 1fr',
                                gridGap: '20px',
                                padding: '20px',
                                '@media (max-width: 1023px)': {
                                    gridTemplateColumns: '1fr 1fr',
                                    gridGap: '15px',
                                    padding: '15px',
                                },
                                '@media (max-width: 800px)': {
                                    gridTemplateColumns: '1fr',
                                    gridGap: '10px',
                                    padding: '10px',
                                }
                            }}>
                                <div>
                                    <div style={{ fontWeight: 700, color: '#2CC2C0' }}>ชื่อ-นามสกุล</div>
                                    <div>{i['pname'] + i['fname'] + ' ' + i['lname']}</div>
                                </div>
                                <div>
                                    <div style={{ fontWeight: 700, color: '#2CC2C0' }}>ที่อยู่</div>
                                    {/* <div>{i['address']['full_address']}</div> */}
                                    <div>{typeof i['address']['full_address'] === 'string' && i['address']['full_address'] !== '' ? i['address']['full_address'] : '-'}</div>
                                </div>
                                <div>
                                    <div style={{ fontWeight: 700, color: '#2CC2C0' }}>เลขประจำตัวประชาชน</div>
                                    <div>{i['pid']}</div>
                                </div>
                                <div>
                                    <div style={{ fontWeight: 700, color: '#2CC2C0' }}>โทรศัพท์</div>
                                    {/* <div>{i['home_phone'] || '-'}</div> */}
                                    <div>{typeof i['home_phone'] === 'string' && i['home_phone'] !== '' ? i['home_phone'] : '-'}</div>
                                </div>
                                <div>
                                    <div style={{ fontWeight: 700, color: '#2CC2C0' }}>วันเกิด</div>
                                    <div>{convertDate(i['birthday'])}</div>
                                </div>
                                <div>
                                    <div style={{ fontWeight: 700, color: '#2CC2C0' }}>โทรศัพท์มือถือ</div>
                                    {/* <div>{i['phone_number'] || '-'}</div> */}
                                    <div>{typeof i['phone_number'] === 'string' && i['phone_number'] !== '' ? i['phone_number'] : '-'}</div>
                                </div>
                                <div>
                                    <div style={{ fontWeight: 700, color: '#2CC2C0' }}>อายุ</div>
                                    <div>{i['age'] + ' ปี'}</div>
                                </div>
                            </Box>
                        </Box>

                        <Box key={idx} sx={{ display: 'grid', gridGap: '20px' }}>
                            <Box sx={{
                                border: 1,
                                borderRadius: 2,
                                borderColor: '#E1E3E9',
                                bgcolor: '#FFF',
                            }}>
                                <Box sx={{
                                    color: '#2CC2C0',
                                    fontWeight: 700,
                                    backgroundColor: '#E1F4EB',
                                    borderBottom: '1px solid #E1E3E9',
                                    borderRadius: '10px',
                                    padding: '10px 20px'
                                }}>ข้อมูลทางการแพทย์</Box>

                                <Box sx={{
                                    display: 'grid',
                                    gridTemplateColumns: '1fr 1fr 1fr 1fr',
                                    gridGap: '20px',
                                    padding: '20px',
                                    '@media (max-width: 1023px)': {
                                        gridTemplateColumns: '1fr 1fr',
                                        gridGap: '15px',
                                        padding: '15px'
                                    },
                                    '@media (max-width: 800px)': {
                                        gridTemplateColumns: '1fr',
                                        gridGap: '10px',
                                        padding: '10px'
                                    }
                                }}>
                                    <div>
                                        <div style={{ fontWeight: 700, color: '#2CC2C0' }}>หมายเลขผู้ป่วยนอก</div>
                                        <div>{typeof i['hn'] === 'string' && i['hn'] !== '' ? i['hn'] : '-'}</div>
                                    </div>
                                    <div>
                                        <div style={{ fontWeight: 700, color: '#2CC2C0' }}>กรุ๊ปเลือด</div>
                                        <div>{typeof i['blood_group'] === 'string' && i['blood_group'] !== '' ? i['blood_group'] : '-'}</div>
                                    </div>
                                    <div>
                                        <div style={{ fontWeight: 700, color: '#2CC2C0' }}>แพ้ยา</div>
                                        <div>{typeof i['drug_allergy'] === 'string' && i['drug_allergy'] !== '' ? i['drug_allergy'] : '-'}</div>
                                    </div>
                                </Box>
                            </Box>

                            <Box sx={{ margin: 'auto' }}>
                                <Pagination
                                    count={maxPage}
                                    page={page}
                                    onChange={handlePageChange}
                                    shape="rounded"
                                    color="primary"
                                />
                            </Box>
                        </Box>
                    </>
                ))}

                <Box sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gridGap: 15,
                    fontSize: 18,
                    '@media (max-width: 1023px)': {
                        fontSize: 16,
                    },
                    '@media (max-width: 800px)': {
                        gridTemplateColumns: '1fr',
                        fontSize: 14,
                    }
                }}>
                    <div onClick={() => { setValue(0) }} className={value === 0 ? styles.btGreen : styles.btGay} >ประวัติผู้ป่วยนอก</div>
                    <div onClick={() => { setValue(1) }} className={value === 1 ? styles.btGreen : styles.btGay} >ประวัติการนอนโรงพยาบาล</div>
                </Box>

                {value === 0 ?
                    <Box sx={{
                        display: 'grid',
                        gridGap: '30px'
                    }}>
                        <Box sx={{
                            border: 1,
                            borderColor: '#E1E3E9',
                            borderRadius: '10px',
                            bgcolor: '#FFF'
                        }}>
                            <Box sx={{
                                color: '#2CC2C0',
                                fontWeight: 700,
                                backgroundColor: '#E1F4EB',
                                borderBottom: '1px solid #E1E3E9',
                                borderRadius: '10px',
                                padding: '10px 20px'
                            }}>ค้นหา</Box>

                            <Box sx={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr',
                                padding: '20px',
                                '@media (max-width: 1023px)': {
                                    gridTemplateColumns: '1fr',
                                    padding: '15px'
                                },
                                '@media (max-width: 800px)': {
                                    justifyItems: 'center',
                                    padding: '10px'
                                },
                            }}>
                                <FormControl sx={{ width: '90%', color: '#2CC2C0' }} size="small">
                                    <div style={{ fontWeight: 700 }}>โรงพยาบาลที่เข้ารับบริการ</div>
                                    <Select
                                        value={hospital || ' '}
                                        onChange={(event) => {
                                            const {
                                                target: { value },
                                            } = event;
                                            setHospital(value)
                                        }}
                                    >
                                        <MenuItem value=" ">ทั้งหมด</MenuItem>
                                        {optionHospital.map((item, index) => (
                                            <MenuItem
                                                key={index}
                                                value={item['key']}
                                            >
                                                {item['value']}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl sx={{ width: '90%', color: '#2CC2C0' }} size="small">
                                    <div style={{ fontWeight: 700 }}>ค้นหาวันที่</div>
                                    <Select
                                        value={date || ' '}
                                        onChange={(event) => {
                                            const {
                                                target: { value },
                                            } = event;
                                            setDate(value)
                                        }}
                                    >
                                        <MenuItem value=" ">วันที่ทั้งหมด</MenuItem>
                                        {optionDate.map((item, index) => (
                                            <MenuItem
                                                key={index}
                                                value={item}
                                            >
                                                {convertDate(item)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>

                        {dataInformation.length > 0 ?
                            <>
                                {dataInformation.map((i, idx) => (
                                    <Box sx={{
                                        display: 'grid',
                                        gridTemplateColumns: '1fr 3fr',
                                        border: 1,
                                        borderColor: '#E1E3E9',
                                        borderRadius: '10px',
                                        height: '400px',
                                        '@media (max-width: 800px)': {
                                            gridTemplateColumns: '1fr 2fr',
                                        }
                                    }} key={idx}>
                                        <Box sx={{
                                            backgroundColor: '#E1F4EB',
                                            borderRadius: '10px',
                                            padding: '20px',
                                            display: 'grid',
                                            gridGap: '20px'
                                        }}>
                                            <Box sx={{
                                                color: '#2CC2C0',
                                                fontWeight: 700,
                                                fontSize: '24px',
                                                textAlign: 'center',
                                                '@media (max-width: 1024px)': {
                                                    fontSize: 18,
                                                },
                                                '@media (max-width: 800px)': {
                                                    fontSize: 14,
                                                }
                                            }}>{convertDate(i['visit_date'])}</Box>
                                            <Button onClick={() => { dataSetMenu(idx, 0) }} variant={menuSelected[idx] === 0 ? 'contained' : 'outlined'}>ประวัติการแพทย์</Button>
                                            <Button onClick={() => { dataSetMenu(idx, 1) }} variant={menuSelected[idx] === 1 ? 'contained' : 'outlined'}>ผลแลป</Button>
                                            <Button onClick={() => { dataSetMenu(idx, 2) }} variant={menuSelected[idx] === 2 ? 'contained' : 'outlined'}>การวินิจฉัย</Button>
                                            <Button onClick={() => { dataSetMenu(idx, 3) }} variant={menuSelected[idx] === 3 ? 'contained' : 'outlined'}>การจ่ายยา</Button>
                                        </Box>

                                        <Box>
                                            {menuSelected[idx] === 0 ?
                                                <Box sx={{ padding: '20px', height: '350px' }}>
                                                    <Box sx={{ color: '#2CC2C0', fontWeight: 700, fontSize: 20 }}>ประวัติการแพทย์</Box>
                                                    {i['visit_information_list'].length > 0 ?
                                                        <Box sx={{ maxHeight: '310px', overflowY: "auto" }}>
                                                            {i['visit_information_list'].map((item, index) => (
                                                                <Box key={index} >
                                                                    <Box sx={{ color: '#2CC2C0', fontWeight: 700 }}>{item['hos_name']}</Box>
                                                                    <Box sx={{
                                                                        display: 'grid',
                                                                        gridTemplateColumns: '1fr 1fr 1fr',
                                                                        margin: '20px',
                                                                        '@media (max-width: 800px)': {
                                                                            gridTemplateColumns: '1fr'
                                                                        }
                                                                    }}>
                                                                        <Box>
                                                                            <div style={{ fontWeight: 700, color: '#2CC2C0' }}>CC</div>
                                                                            <div style={{ margin: '0 10%' }}>{item['cc'] === '' ? '-' : item['cc']}</div>
                                                                        </Box>
                                                                        <Box>
                                                                            <div style={{ fontWeight: 700, color: '#2CC2C0' }}>Ht</div>
                                                                            <div style={{ margin: '0 10%' }}>{item['ht'] === '' ? '-' : item['ht']}</div>
                                                                        </Box>
                                                                        <Box>
                                                                            <div style={{ fontWeight: 700, color: '#2CC2C0' }}>Pi</div>
                                                                            <div style={{ margin: '0 10%' }}>{item['pi'] === '' ? '-' : item['pi']}</div>
                                                                        </Box>
                                                                        <Box>
                                                                            <div style={{ fontWeight: 700, color: '#2CC2C0' }}>Bt</div>
                                                                            <div style={{ margin: '0 10%' }}>{item['bt'] === '' ? '-' : item['bt']}</div>
                                                                        </Box>
                                                                        <Box>
                                                                            <div style={{ fontWeight: 700, color: '#2CC2C0' }}>Pe</div>
                                                                            <div style={{ margin: '0 10%' }}>{item['pe'] === '' ? '-' : item['pe']}</div>
                                                                        </Box>
                                                                        <Box>
                                                                            <div style={{ fontWeight: 700, color: '#2CC2C0' }}>Rr</div>
                                                                            <div style={{ margin: '0 10%' }}>{item['rr'] === '' ? '-' : item['rr']}</div>
                                                                        </Box>
                                                                        <Box>
                                                                            <div style={{ fontWeight: 700, color: '#2CC2C0' }}>Bp</div>
                                                                            <div style={{ margin: '0 10%' }}>{item['bp'] === '' ? '-' : item['bp']}</div>
                                                                        </Box>
                                                                        <Box>
                                                                            <div style={{ fontWeight: 700, color: '#2CC2C0' }}>Bmi</div>
                                                                            <div style={{ margin: '0 10%' }}>{item['bmi'] === '' ? '-' : item['bmi']}</div>
                                                                        </Box>
                                                                        <Box>
                                                                            <div style={{ fontWeight: 700, color: '#2CC2C0' }}>Pr</div>
                                                                            <div style={{ margin: '0 10%' }}>{item['pr'] === '' ? '-' : item['pr']}</div>
                                                                        </Box>
                                                                        <Box>
                                                                            <div style={{ fontWeight: 700, color: '#2CC2C0' }}>sat O2</div>
                                                                            <div style={{ margin: '0 10%' }}>{item['sat_o2'] === '' ? '-' : item['sat_o2']}</div>
                                                                        </Box>
                                                                        <Box>
                                                                            <div style={{ fontWeight: 700, color: '#2CC2C0' }}>Bw</div>
                                                                            <div style={{ margin: '0 10%' }}>{item['bw'] === '' ? '-' : item['bw']}</div>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                            ))}
                                                        </Box>
                                                        :
                                                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>ไม่พบข้อมูล</Box>
                                                    }
                                                </Box>
                                                : null
                                            }

                                            {menuSelected[idx] === 1 ?
                                                <Box sx={{ padding: '20px', height: '350px' }}>
                                                    <Box sx={{ color: '#2CC2C0', fontWeight: 700, fontSize: 20 }}>ผลแลป</Box>
                                                    {i['labs_information_list'].length > 0 ?
                                                        <Box sx={{ maxHeight: '310px', overflowY: "auto" }}>
                                                            {i['labs_information_list'].map((item, index) => (
                                                                <Box key={index}>
                                                                    <Box sx={{ color: '#2CC2C0', fontWeight: 700 }}>{item['hos_name']}</Box>
                                                                    {item['lab_list'].map((i, idx) => (
                                                                        <Box key={idx}>
                                                                            <Box sx={{ fontWeight: 700, color: '#2CC2C0', fontSize: 16 }}>{i['ordername']}</Box>
                                                                            {i['detail'].map((item, index) => (
                                                                                <Box key={index} sx={{ display: 'flex', gridGap: 5, margin: '0 10px' }}>
                                                                                    <CircleIcon style={{ width: '5px' }} /> {item}
                                                                                </Box>
                                                                            ))}
                                                                        </Box>
                                                                    ))}
                                                                </Box>
                                                            ))}
                                                        </Box>
                                                        :
                                                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>ไม่พบข้อมูล</Box>
                                                    }
                                                </Box>
                                                : null
                                            }

                                            {menuSelected[idx] === 2 ?
                                                <Box sx={{ padding: '20px', height: '350px' }}>
                                                    <Box sx={{ color: '#2CC2C0', fontWeight: 700, fontSize: 20 }}>การวินิจฉัย</Box>
                                                    {i['diagnosis_information_list'].length > 0 ?
                                                        <Box sx={{ height: '310px', overflowY: "auto" }}>
                                                            {i['diagnosis_information_list'].map((item, index) => (
                                                                <Box key={index} >
                                                                    <Box sx={{ color: '#2CC2C0', fontWeight: 700, marginBottom: '20px' }}>{item['hos_name']}</Box>
                                                                    {item['diagnosis'].map((i, idx) => (
                                                                        <Box key={idx} sx={{ display: 'flex', gridGap: 5, margin: '0 20px' }}>
                                                                            <CircleIcon style={{ width: '5px' }} /> {i['diag']}
                                                                        </Box>
                                                                    ))}
                                                                </Box>
                                                            ))}
                                                        </Box>
                                                        :
                                                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>ไม่พบข้อมูล</Box>
                                                    }
                                                </Box>
                                                : null
                                            }

                                            {menuSelected[idx] === 3 ?
                                                <Box sx={{ padding: '20px', height: '350px' }}>
                                                    <Box sx={{ color: '#2CC2C0', fontWeight: 700, fontSize: 20 }}>การจ่ายยา</Box>
                                                    {i['order_information_list'].length > 0 ?
                                                        <Box sx={{ height: '310px', overflowY: "auto" }}>
                                                            {i['order_information_list'].map((item, index) => (
                                                                <Box key={index}>
                                                                    <Box sx={{ color: '#2CC2C0', fontWeight: 700, marginBottom: '20px' }}>{item['hos_name']}</Box>
                                                                    {item['order_list'].length > 0 ?
                                                                        <Box>
                                                                            {item['order_list'].map((i, idx) => (
                                                                                <Box key={idx} sx={{ display: 'flex', gridGap: 5, margin: '0 20px' }}>
                                                                                    <CircleIcon style={{ width: '5px' }} /> {i['med']}
                                                                                </Box>
                                                                            ))}
                                                                        </Box>
                                                                        :
                                                                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>ไม่พบข้อมูล</Box>
                                                                    }
                                                                </Box>
                                                            ))}
                                                        </Box>
                                                        :
                                                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>ไม่พบข้อมูล</Box>
                                                    }
                                                </Box>
                                                : null
                                            }
                                        </Box>
                                    </Box>
                                ))}
                            </>
                            :
                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>ไม่พบข้อมูล</Box>
                        }
                    </Box>
                    :
                    <TestData
                        pid={pid}
                    />
                }

            </Box>
        </div>
    )
}

function TestData({ pid }) {

    const [loading, setLoading] = useState(false)

    const [hospital, setHospital] = useState('')
    const [optionHospital, setOptionHospital] = useState([])
    const [date, setDate] = useState('')
    const [optionDate, setOptionDate] = useState([])

    const [dataAll, setDataAll] = useState([])

    const [dataInformationIPD, setDataInformationIPD] = useState([])
    const [menuSelected, setMenuSelected] = useState();



    useEffect(() => {
        filterDataHospital() //option hospital
    }, [])

    useEffect(() => {
        findInformationIPD()
    }, [hospital])

    useEffect(() => {
        if (date === '' || date === ' ') {
            setDataInformationIPD(dataAll)
        } else {
            filterDataInformationDate()
        }

    }, [date])

    const filterDataHospital = async () => { //option hospital
        setLoading(true)
        let res = await api.findInformationIPD(pid, '')
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                let data = res['data']['data']
                let option = []
                for (let i = 0; i < data['visit_information_ipd_list'].length; i++) {
                    if (data['visit_information_ipd_list'][i]) {
                        option.push({
                            key: data['visit_information_ipd_list'][i]['hos_id'],
                            value: data['visit_information_ipd_list'][i]['hos_name']
                        })
                    }
                }
                const unique = [...new Map(option.map((m) => [m.key, m])).values()] //กรองชื่อซ้ำ
                setOptionHospital(unique)
                // console.log(unique)
            } else {
                notifyError(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const findInformationIPD = async () => {
        setLoading(true)
        let res = await api.findInformationIPD(pid, hospital === ' ' ? '' : hospital)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {

                let raw = res['data']
                let date_list = raw.data.diagnosis_information_ipd_list.map(item => ({ hos_id: item.hos_id, date_admit: item.date_admit, diagnosis_information_ipd_list: [], labs_information_ipd_list: [], order_information_ipd_list: [], proced_information_ipd_list: [], visit_information_ipd_list: [] }));
                date_list = date_list.concat(raw.data.labs_information_ipd_list.map(item => ({ hos_id: item.hos_id, date_admit: item.date_admit, diagnosis_information_ipd_list: [], labs_information_ipd_list: [], order_information_ipd_list: [], proced_information_ipd_list: [], visit_information_ipd_list: [] })));
                date_list = date_list.concat(raw.data.order_information_ipd_list.map(item => ({ hos_id: item.hos_id, date_admit: item.date_admit, diagnosis_information_ipd_list: [], labs_information_ipd_list: [], order_information_ipd_list: [], proced_information_ipd_list: [], visit_information_ipd_list: [] })));
                date_list = date_list.concat(raw.data.proced_information_ipd_list.map(item => ({ hos_id: item.hos_id, date_admit: item.date_admit, diagnosis_information_ipd_list: [], labs_information_ipd_list: [], order_information_ipd_list: [], proced_information_ipd_list: [], visit_information_ipd_list: [] })));
                date_list = date_list.concat(raw.data.visit_information_ipd_list.map(item => ({ hos_id: item.hos_id, date_admit: item.admit_date, diagnosis_information_ipd_list: [], labs_information_ipd_list: [], order_information_ipd_list: [], proced_information_ipd_list: [], visit_information_ipd_list: [] })));
                date_list = _.orderBy(_.uniqBy(date_list, 'date_admit'), ['date_admit'], ['desc'])

                date_list.forEach((item_) => {
                    item_.diagnosis_information_ipd_list = raw.data.diagnosis_information_ipd_list.filter(item => item.date_admit === item_.date_admit)
                    item_.labs_information_ipd_list = raw.data.labs_information_ipd_list.filter(item => item.date_admit === item_.date_admit)
                    item_.order_information_ipd_list = raw.data.order_information_ipd_list.filter(item => item.date_admit === item_.date_admit)
                    item_.proced_information_ipd_list = raw.data.proced_information_ipd_list.filter(item => item.date_admit === item_.date_admit)
                    item_.visit_information_ipd_list = raw.data.visit_information_ipd_list.filter(item => item.admit_date === item_.date_admit)
                })
                console.log(date_list)
                setDataInformationIPD(date_list)
                setMenuSelected(Array(date_list.length).fill(0))
                setDataAll(date_list)

                const List = []
                for (let i = 0; i < date_list.length; i++) {
                    if (date_list[i]) {
                        List.push(date_list[i]['date_admit'])
                    }
                }
                const unique = [...new Map(List.map((m) => [m, m])).values()] //กรองชื่อซ้ำ
                setOptionDate(unique)

            } else {
                notifyError(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const filterDataInformationDate = () => {
        const List = []
        for (let i = 0; i < dataAll.length; i++) {
            if (dataAll[i]['date_admit'] === date) {
                List.push(dataAll[i])
            }
        }
        setDataInformationIPD(List)
        console.log(List)
    }

    const convertDate = (dataDate) => {
        const date = new Date(dataDate)
        const optionDate = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timeZone: 'UTC',
        }

        const thaiLocale = 'th-TH';
        const formattedDate = date.toLocaleDateString(thaiLocale, optionDate)

        const newDate = `${formattedDate}`
        return newDate
    }

    const dataSetMenu = (index, value) => {
        let tmp = menuSelected;
        if (menuSelected.length != dataInformationIPD.length) {
            tmp = Array(dataInformationIPD.length).fill(0);
        }

        tmp[index] = value;
        setMenuSelected([...tmp]);
    }

    return (
        <>
            <Loader loading={loading} />
            <Box sx={{
                display: 'grid',
                gridGap: '30px'
            }}>
                <Box sx={{
                    border: 1,
                    borderColor: '#E1E3E9',
                    borderRadius: '10px',
                    bgcolor: '#FFF'
                }}>
                    <Box sx={{
                        color: '#2CC2C0',
                        fontWeight: 700,
                        backgroundColor: '#E1F4EB',
                        borderBottom: '1px solid #E1E3E9',
                        borderRadius: '10px',
                        padding: '10px 20px'
                    }}>ค้นหา</Box>

                    <Box sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        padding: '20px',
                        '@media (max-width: 1023px)': {
                            gridTemplateColumns: '1fr',
                            padding: '15px'
                        },
                        '@media (max-width: 800px)': {
                            justifyItems: 'center',
                            padding: '10px'
                        },
                    }}>
                        <FormControl sx={{ width: '90%', color: '#2CC2C0' }} size="small">
                            <div style={{ fontWeight: 700 }}>โรงพยาบาลที่เข้ารับบริการ</div>
                            <Select
                                value={hospital || ' '}
                                onChange={(event) => {
                                    const {
                                        target: { value },
                                    } = event;
                                    setHospital(value)
                                    setDate('')
                                }}
                            >
                                <MenuItem value=" ">ทั้งหมด</MenuItem>
                                {optionHospital.map((item, index) => (
                                    <MenuItem
                                        key={index}
                                        value={item['key']}
                                    >
                                        {item['value']}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl sx={{ width: '90%', color: '#2CC2C0' }} size="small">
                            <div style={{ fontWeight: 700 }}>ค้นหาวันที่</div>
                            <Select
                                value={date || ' '}
                                onChange={(event) => {
                                    const {
                                        target: { value },
                                    } = event;
                                    setDate(value)
                                }}
                            >
                                <MenuItem value=" ">วันที่ทั้งหมด</MenuItem>
                                {optionDate.map((item, index) => (
                                    <MenuItem
                                        key={index}
                                        value={item}
                                    >
                                        {convertDate(item)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>

                {dataInformationIPD.length > 0 ?
                    <>
                        {dataInformationIPD.map((i, idx) => (
                            <Box sx={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 3fr',
                                border: 1,
                                borderColor: '#E1E3E9',
                                borderRadius: '10px',
                                '@media (max-width: 800px)': {
                                    gridTemplateColumns: '1fr 2fr',
                                }
                            }} key={idx}>
                                <Box sx={{
                                    backgroundColor: '#E1F4EB',
                                    borderRadius: '10px',
                                    padding: '20px',
                                    display: 'grid',
                                    gridGap: '20px'
                                }}>
                                    <Box sx={{
                                        color: '#2CC2C0',
                                        fontWeight: 700,
                                        fontSize: '24px',
                                        textAlign: 'center',
                                        '@media (max-width: 1024px)': {
                                            fontSize: 18,
                                        },
                                        '@media (max-width: 800px)': {
                                            fontSize: 14,
                                        }
                                    }}>{convertDate(i['date_admit'])}</Box>
                                    <Button onClick={() => { dataSetMenu(idx, 0) }} variant={menuSelected[idx] === 0 ? 'contained' : 'outlined'}>ประวัติการนอน รพ.</Button>
                                    <Button onClick={() => { dataSetMenu(idx, 1) }} variant={menuSelected[idx] === 1 ? 'contained' : 'outlined'}>การเข้ารับการรักษา</Button>
                                    <Button onClick={() => { dataSetMenu(idx, 2) }} variant={menuSelected[idx] === 2 ? 'contained' : 'outlined'}>การวินิจฉัย</Button>
                                    <Button onClick={() => { dataSetMenu(idx, 3) }} variant={menuSelected[idx] === 3 ? 'contained' : 'outlined'}>การตรวจแลป</Button>
                                    <Button onClick={() => { dataSetMenu(idx, 4) }} variant={menuSelected[idx] === 4 ? 'contained' : 'outlined'}>รายการยา</Button>
                                    {/* <Button onClick={() => { dataSetMenu(idx, 4) }} variant={menuSelected[idx] === 4 ? 'contained' : 'outlined'} style={{ display: 'grid' }}><div>การจ่ายยา</div><div>ขณะนอนโรงพยาบาล</div></Button> */}
                                    {/* <Button onClick={() => { dataSetMenu(idx, 5) }} variant={menuSelected[idx] === 5 ? 'contained' : 'outlined'}>การจ่ายยา กลับบ้าน</Button> */}
                                    <Button onClick={() => { dataSetMenu(idx, 6) }} variant={menuSelected[idx] === 6 ? 'contained' : 'outlined'}>การทำหัตถการ</Button>
                                </Box>

                                <Box>
                                    {menuSelected[idx] === 0 ?
                                        <Box sx={{ padding: '20px', height: '600px' }}>
                                            <Box sx={{ color: '#2CC2C0', fontWeight: 700, fontSize: 20 }}>ประวัติการนอน รพ.</Box>
                                            {i['visit_information_ipd_list'].length > 0 ?
                                                <Box sx={{ maxHeight: '550px', overflowY: "auto" }}>
                                                    {i['visit_information_ipd_list'].map((item, index) => (
                                                        <Box>
                                                            <Box sx={{ color: '#2CC2C0', fontWeight: 700 }}>{item['hos_name']}</Box>
                                                            <Box sx={{ margin: '20px' }}>
                                                                <Box sx={{
                                                                    display: 'grid',
                                                                    gridTemplateColumns: '1fr 1fr 1fr',
                                                                    gridGap: '50px 10px',
                                                                    '@media (max-width: 800px)': {
                                                                        gridGap: '20px',
                                                                        gridTemplateColumns: '1fr',
                                                                    }
                                                                }} key={index}>
                                                                    <Box>
                                                                        <div style={{ color: '#2CC2C0', fontWeight: 700 }}>วันที่แอดมิด</div>
                                                                        <div style={{ marginLeft: '15px' }}>{convertDate(item['admit_date'])}</div>
                                                                    </Box>
                                                                    <Box>
                                                                        <div style={{ color: '#2CC2C0', fontWeight: 700 }}>เวลาที่แอดมิด</div>
                                                                        <div style={{ marginLeft: '15px' }}>{item['admit_time']}</div>
                                                                    </Box>
                                                                    <Box>
                                                                        <div style={{ color: '#2CC2C0', fontWeight: 700 }}>แผนกที่แอดมิด</div>
                                                                        <div style={{ marginLeft: '15px' }}>{item['admit_clinic']}</div>
                                                                    </Box>
                                                                    <Box>
                                                                        <div style={{ color: '#2CC2C0', fontWeight: 700 }}>วอร์ดที่แอดมิด</div>
                                                                        <div style={{ marginLeft: '15px' }}>{item['admit_ward']}</div>
                                                                    </Box>
                                                                    <Box>
                                                                        <div style={{ color: '#2CC2C0', fontWeight: 700 }}>วันที่ออกจาก รพ.</div>
                                                                        <div style={{ marginLeft: '15px' }}>{convertDate(item['dch_date'])}</div>
                                                                    </Box>
                                                                    <Box>
                                                                        <div style={{ color: '#2CC2C0', fontWeight: 700 }}>เวลาที่ออกจาก รพ.</div>
                                                                        <div style={{ marginLeft: '15px' }}>{item['dch_time']}</div>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    ))}
                                                </Box>
                                                :
                                                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>ไม่พบข้อมูล</Box>
                                            }
                                        </Box>
                                        : null
                                    }

                                    {menuSelected[idx] === 1 ?
                                        <Box sx={{ padding: '20px', height: '600px' }}>
                                            <Box sx={{ color: '#2CC2C0', fontWeight: 700, fontSize: 20 }}>ประวัติการเข้ารับการรักษา</Box>
                                            {i['visit_information_ipd_list'].length > 0 ?
                                                <Box sx={{ maxHeight: '550px', overflowY: "auto" }}>
                                                    <Box sx={{ margin: '20px' }}>
                                                        {i['visit_information_ipd_list'].map((item, index) => (
                                                            <Box sx={{
                                                                display: 'grid',
                                                                gridTemplateColumns: '1fr 1fr 1fr',
                                                                gridGap: '20px',
                                                                '@media (max-width: 800px)': {
                                                                    gridGap: '10px',
                                                                    gridTemplateColumns: '1fr',
                                                                }
                                                            }}>
                                                                <Box>
                                                                    <div style={{ color: '#2CC2C0', fontWeight: 700 }}>bmi</div>
                                                                    <div style={{ marginLeft: '15px' }}>{item['bmi'] ? item['bmi'] : '-'}</div>
                                                                </Box>
                                                                <Box>
                                                                    <div style={{ color: '#2CC2C0', fontWeight: 700 }}>bp</div>
                                                                    <div style={{ marginLeft: '15px' }}>{item['bp'] ? item['bp'] : '-'}</div>
                                                                </Box>
                                                                <Box>
                                                                    <div style={{ color: '#2CC2C0', fontWeight: 700 }}>bt</div>
                                                                    <div style={{ marginLeft: '15px' }}>{item['bt'] ? item['bt'] : '-'}</div>
                                                                </Box>
                                                                <Box>
                                                                    <div style={{ color: '#2CC2C0', fontWeight: 700 }}>bw</div>
                                                                    <div style={{ marginLeft: '15px' }}>{item['bw'] ? item['bw'] : '-'}</div>
                                                                </Box>
                                                                <Box>
                                                                    <div style={{ color: '#2CC2C0', fontWeight: 700 }}>cc</div>
                                                                    <div style={{ marginLeft: '15px' }}>{item['cc'] ? item['cc'] : '-'}</div>
                                                                </Box>
                                                                <Box>
                                                                    <div style={{ color: '#2CC2C0', fontWeight: 700 }}>code</div>
                                                                    <div style={{ marginLeft: '15px' }}>{item['code'] ? item['code'] : '-'}</div>
                                                                </Box>
                                                                <Box>
                                                                    <div style={{ color: '#2CC2C0', fontWeight: 700 }}>ht</div>
                                                                    <div style={{ marginLeft: '15px' }}>{item['ht'] ? item['ht'] : '-'}</div>
                                                                </Box>
                                                                <Box>
                                                                    <div style={{ color: '#2CC2C0', fontWeight: 700 }}>pe</div>
                                                                    <div style={{ marginLeft: '15px' }}>{item['pe'] ? item['pe'] : '-'}</div>
                                                                </Box>
                                                                <Box>
                                                                    <div style={{ color: '#2CC2C0', fontWeight: 700 }}>pi</div>
                                                                    <div style={{ marginLeft: '15px' }}>{item['pi'] ? item['pi'] : '-'}</div>
                                                                </Box>
                                                                <Box>
                                                                    <div style={{ color: '#2CC2C0', fontWeight: 700 }}>pr</div>
                                                                    <div style={{ marginLeft: '15px' }}>{item['pr'] ? item['pr'] : '-'}</div>
                                                                </Box>
                                                                <Box>
                                                                    <div style={{ color: '#2CC2C0', fontWeight: 700 }}>rr</div>
                                                                    <div style={{ marginLeft: '15px' }}>{item['rr'] ? item['rr'] : '-'}</div>
                                                                </Box>
                                                                <Box>
                                                                    <div style={{ color: '#2CC2C0', fontWeight: 700 }}>sat_o2</div>
                                                                    <div style={{ marginLeft: '15px' }}>{item['sat_o2'] ? item['sat_o2'] : '-'}</div>
                                                                </Box>
                                                            </Box>
                                                        ))}
                                                    </Box>
                                                </Box>
                                                :
                                                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>ไม่พบข้อมูล</Box>
                                            }
                                        </Box>
                                        : null
                                    }

                                    {menuSelected[idx] === 2 ?
                                        <Box sx={{ padding: '20px', height: '600px' }}>
                                            <Box sx={{ color: '#2CC2C0', fontWeight: 700, fontSize: 20 }}>ประวัติการวินิจฉัย</Box>
                                            {i['diagnosis_information_ipd_list'].length > 0 ?
                                                <Box sx={{ height: '550px', overflowY: "auto" }}>
                                                    {i['diagnosis_information_ipd_list'].map((item, index) => (
                                                        <Box sx={{ margin: '20px' }} key={index}>
                                                            {item['diagnosis'].map((i, idx) => (
                                                                <Box key={idx}>
                                                                    <Box sx={{
                                                                        display: 'grid',
                                                                        gridTemplateColumns: '1fr 1fr',
                                                                        '@media (max-width: 800px)': {
                                                                            gridTemplateColumns: '1fr',
                                                                            gridGap: '10px'
                                                                        }
                                                                    }}>
                                                                        <Box>
                                                                            <div style={{ color: '#2CC2C0', fontWeight: 700 }}>วันที่วินิจฉัย</div>
                                                                            <div style={{ marginLeft: '20px' }}>{convertDate(i['diagnosis_date'])}</div>
                                                                        </Box>
                                                                        <Box>
                                                                            <div style={{ color: '#2CC2C0', fontWeight: 700 }}>ผลการวินิจฉัย</div>
                                                                            <div style={{ marginLeft: '20px' }}>{i['diag']}</div>
                                                                        </Box>
                                                                    </Box>
                                                                    <Box sx={{ borderTop: 1, borderColor: '#2CC2C0', margin: '30px 0' }}></Box>
                                                                </Box>
                                                            ))}
                                                        </Box>
                                                    ))}
                                                </Box>
                                                :
                                                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>ไม่พบข้อมูล</Box>
                                            }
                                        </Box>
                                        : null
                                    }

                                    {menuSelected[idx] === 3 ?
                                        <Box sx={{ padding: '20px', height: '600px' }}>
                                            <Box sx={{ color: '#2CC2C0', fontWeight: 700, fontSize: 20 }}>ประวัติการตรวจแลป</Box>
                                            {i['labs_information_ipd_list'].length > 0 ?
                                                <Box sx={{ height: '550px', overflowY: "auto" }}>
                                                    {i['labs_information_ipd_list'].map((item, index) => (
                                                        <Box key={index}>
                                                            {item['lab_list'].map((i, idx) => (
                                                                <Box sx={{ margin: '20px' }}>
                                                                    <Box key={idx}>
                                                                        {i['detail'].map((item, index) => (
                                                                            <Box key={index} sx={{ display: 'flex', gridGap: 5 }}>
                                                                                <CircleIcon style={{ width: '5px' }} /> {item}
                                                                            </Box>
                                                                        ))}
                                                                    </Box>
                                                                </Box>
                                                            ))}
                                                        </Box>
                                                    ))}
                                                </Box>
                                                :
                                                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>ไม่พบข้อมูล</Box>
                                            }
                                        </Box>
                                        : null
                                    }

                                    {menuSelected[idx] === 4 ?
                                        <Box sx={{ padding: '20px', height: '600px' }}>
                                            <Box sx={{ color: '#2CC2C0', fontWeight: 700, fontSize: 20 }}>ประวัติการจ่ายยา</Box>
                                            {i['order_information_ipd_list'].length > 0 ?
                                                <Box sx={{ height: '550px', overflowY: "auto" }}>
                                                    {i['order_information_ipd_list'].map((item, index) => (
                                                        <Box key={index}>
                                                            {item['order_list'].map((i, idx) => (
                                                                <Box key={idx}>
                                                                    <Box sx={{ margin: '20px' }}>
                                                                        <Box sx={{
                                                                            display: 'grid',
                                                                            gridTemplateColumns: '1fr 1fr 1fr',
                                                                            gridGap: '20px',
                                                                            '@media (max-width: 800px)': {
                                                                                gridTemplateColumns: '1fr',
                                                                                gridGap: '10px'
                                                                            }
                                                                        }} key={index}>
                                                                            <Box>
                                                                                <div style={{ color: '#2CC2C0', fontWeight: 700 }}>วันที่ได้รับยา</div>
                                                                                <div style={{ marginLeft: '15px' }}>{convertDate(i['verify_date'])}</div>
                                                                            </Box>
                                                                            <Box>
                                                                                <div style={{ color: '#2CC2C0', fontWeight: 700 }}>รายการยา</div>
                                                                                <div style={{ marginLeft: '15px' }}>{i['drug']}</div>
                                                                            </Box>
                                                                            <Box>
                                                                                <div style={{ color: '#2CC2C0', fontWeight: 700 }}>วิธีการใช้ยา</div>
                                                                                <div style={{ marginLeft: '15px' }}>{i['drugUse']}</div>
                                                                            </Box>
                                                                            <Box>
                                                                                <div style={{ color: '#2CC2C0', fontWeight: 700 }}>ปริมาณยาที่ได้รับ</div>
                                                                                <div style={{ marginLeft: '15px' }}>{i['drugAmount']}</div>
                                                                            </Box>
                                                                            <Box>
                                                                                <div style={{ color: '#2CC2C0', fontWeight: 700 }}>med</div>
                                                                                <div style={{ marginLeft: '15px' }}>{i['med']}</div>
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                    <Box sx={{ borderTop: 1, borderColor: '#2CC2C0', margin: '30px 0' }}></Box>
                                                                </Box>
                                                            ))}
                                                        </Box>
                                                    ))}
                                                </Box>
                                                :
                                                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>ไม่พบข้อมูล</Box>
                                            }
                                        </Box>
                                        : null
                                    }

                                    {/* {menuSelected[idx] === 4 ?
                                <Box sx={{ padding: '20px', height: '600px' }}>
                                    <Box sx={{ color: '#2CC2C0', fontWeight: 700, fontSize: 20 }}>ประวัติการจ่ายยา ขณะนอนโรงพยาบาล</Box>
                                    <Box>
                                        <Box sx={{ height: '550px', overflowY: "auto" }}>
                                            <Box sx={{ margin: '20px' }}>

                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                : null
                            }

                            {menuSelected[idx] === 5 ?
                                <Box sx={{ padding: '20px', height: '600px' }}>
                                    <Box sx={{ color: '#2CC2C0', fontWeight: 700, fontSize: 20 }}>ประวัติการจ่ายยา กลับบ้าน</Box>
                                    <Box>
                                        <Box sx={{ height: '550px', overflowY: "auto" }}>
                                            <Box sx={{ margin: '20px' }}>

                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                : null
                            } */}

                                    {menuSelected[idx] === 6 ?
                                        <Box sx={{ padding: '20px', height: '600px' }}>
                                            <Box sx={{ color: '#2CC2C0', fontWeight: 700, fontSize: 20 }}>ประวัติการทำหัตถการ</Box>
                                            {i['proced_information_ipd_list'].length > 0 ?
                                                <Box sx={{ height: '550px', overflowY: "auto" }}>
                                                    {i['proced_information_ipd_list'].map((item, index) => (
                                                        <Box key={index}>
                                                            {item['proced'].map((i, idx) => (
                                                                <Box key={idx}>
                                                                    <Box sx={{ margin: '20px' }}>
                                                                        <Box sx={{
                                                                            display: 'grid',
                                                                            gridTemplateColumns: '1fr 1fr 1fr',
                                                                            gridGap: '20px',
                                                                            '@media (max-width: 800px)': {
                                                                                gridTemplateColumns: '1fr',
                                                                                gridGap: '10px'
                                                                            }
                                                                        }} key={index}>
                                                                            <Box>
                                                                                <div style={{ color: '#2CC2C0', fontWeight: 700 }}>วันที่ทำหัตถการ</div>
                                                                                <div style={{ marginLeft: '15px' }}>{convertDate(i['proced_date'])}</div>
                                                                            </Box>
                                                                            <Box>
                                                                                <div style={{ color: '#2CC2C0', fontWeight: 700 }}>วอร์ด</div>
                                                                                <div style={{ marginLeft: '15px' }}>{i['proced_ward']}</div>
                                                                            </Box>
                                                                            <Box>
                                                                                <div style={{ color: '#2CC2C0', fontWeight: 700 }}>รายการ</div>
                                                                                <div style={{ marginLeft: '15px' }}>{i['proced']}</div>
                                                                            </Box>
                                                                        </Box>
                                                                        <Box sx={{ borderTop: 1, borderColor: '#2CC2C0', margin: '30px 0' }}></Box>
                                                                    </Box>
                                                                </Box>
                                                            ))}
                                                        </Box>
                                                    ))}
                                                </Box>
                                                :
                                                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>ไม่พบข้อมูล</Box>
                                            }
                                        </Box>
                                        : null
                                    }

                                </Box>
                            </Box>
                        ))}
                    </>
                    :
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>ไม่พบข้อมูล</Box>
                }
            </Box>
        </>
    )
}